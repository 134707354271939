function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { WDHData8 } from '../../../../../../wdh-feature/wdh-data8';
var createContinueButton = function createContinueButton(container) {
  var button = document.createElement('a');
  button.className = 'wffm-continue';
  button.innerText = 'Continue';
  button.setAttribute('href', '#');
  container.appendChild(button);
  return button;
};
var validateInput = function validateInput(input) {
  var inputType = input.type;
  switch (inputType) {
    case 'text':
      {
        if (input.value === null || input.value === '') {
          return false;
        }
        return true;
        break;
      }
    case 'email':
      {
        var emailRegex = /\S+@\S+\.\S+/;
        if (input.value === null || input.value === '' || emailRegex.test(String(input.value).toLowerCase()) === false) {
          return false;
        }
        return true;
        break;
      }
    case 'checkbox':
      {
        if (input.checked === false) {
          input.classList.add('checkbox-invalid');
          return false;
        }
        input.classList.remove('checkbox-invalid');
        return true;
        break;
      }
    case 'tel':
      {
        var numberRegex = /^\+?\s{0,}\d{0,}\s{0,}(\(\s{0,}\d{1,}\s{0,}\)\s{0,}|\d{0,}\s{0,}-?\s{0,})\d{2,}\s{0,}-?\s{0,}\d{2,}\s{0,}(-?\s{0,}\d{2,}|\s{0,})\s{0,}$/;
        if (input.value === null || input.value === '' || numberRegex.test(String(input.value).toLowerCase()) === false) {
          return false;
        }
        return true;
        break;
      }
    default:
      {
        return true;
      }
  }
};
var wffmFormsWrappers = document.querySelectorAll('.form-two-column');
wffmFormsWrappers.forEach(function (form) {
  var fieldSets = form.querySelectorAll('.scfSectionBorderAsFieldSet');
  var scfFooterBorder = form.querySelector('.scfFooterBorder');
  var scfSubmitButtonBorder = form.querySelector('.scfSubmitButtonBorder');
  if (fieldSets.length > 1) {
    var stepIndicator = 1;
    fieldSets.forEach(function (fieldSet, index) {
      if (index !== 0) {
        fieldSet.parentNode.classList.add('field-hidden');
      }
      if (index !== fieldSets.length - 1) {
        fieldSet.parentNode.classList.add('field-not-last');
      }
      if (index !== fieldSets.length - 1) {
        var button = createContinueButton(fieldSet.parentNode);
        button.addEventListener('click', function (e) {
          e.preventDefault();
          var isValid = true;
          var inputContainers = fieldSet.querySelectorAll('.scfSectionContent > div');
          inputContainers.forEach(function (container) {
            var isRequired = container.querySelector('.scfRequired');
            var input = container.querySelector('input');
            if (input !== null && !validateInput(input) && isRequired !== null) {
              container.querySelector('.scfValidator').style.display = 'block';
              isValid = false;
              input.classList.add('field-invalid');
            } else if (input !== null && validateInput(input) && isRequired !== null) {
              input.classList.remove('field-invalid');
            }
          });
          if (isValid === true) {
            var postCodeLookup = fieldSet.querySelector('.postcodeLookup');
            if (postCodeLookup !== null) {
              //postCodeLookup.click();
            }
            fieldSets[index + 1].parentNode.classList.remove('field-hidden');
            fieldSets[index].parentNode.classList.add('field-hidden');
            stepIndicator++;
            if (stepIndicator === fieldSets.length) {
              if (scfFooterBorder.innerText.replace(/\s/g, '') !== null && scfFooterBorder.innerText.replace(/\s/g, '') !== '') {
                scfFooterBorder.classList.remove('field-hidden');
              }
              fieldSets[index + 1].parentNode.classList.add('field-final');
              scfSubmitButtonBorder.classList.remove('field-hidden');
            }
            if (window.matchMedia('(max-width: 991px)').matches) {
              form.scrollIntoView({
                behavior: 'smooth'
              });
            }
          }
        });
        var config = {
          attributes: true,
          childList: true,
          subtree: true
        };
        var callback = function callback(mutationsList) {
          var _iterator = _createForOfIteratorHelper(mutationsList),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var mutation = _step.value;
              if (mutation.type === 'childList') {
                var postCodeButton = fieldSet.querySelector('.postcodeLookup');
                if (postCodeButton !== null) {
                  fieldSet.parentNode.classList.add('field-continue-hidden');
                  postCodeButton.addEventListener('click', function (e) {
                    window.alert = function () {};
                    setTimeout(function () {
                      var data8Validator = form.querySelector('.data8Validator');
                      var data8Container = form.querySelector('.data8-container');
                      var data8Dropdown = form.querySelector('.data8-postcodelookup-dropdown');
                      var addressField = form.querySelector('[class~="name.Address"] input');
                      var cityField = form.querySelector('[class~="name.City"] input');
                      if ((addressField.value === '' || cityField.value === '') && data8Dropdown === null) {
                        if (data8Validator === null) {
                          var errorMsg = document.createElement('p');
                          errorMsg.innerText = 'Post code entered is not valid.';
                          errorMsg.classList.add('scfValidator', 'data8Validator');
                          data8Container.append(errorMsg);
                        }
                        var inputContainers = fieldSet.querySelectorAll('.scfSectionContent > div');
                        inputContainers.forEach(function (container) {
                          var isRequired = container.querySelector('.scfRequired');
                          var input = container.querySelector('input');
                          if (input !== null && !validateInput(input) && isRequired !== null) {
                            container.querySelector('.scfValidator').style.display = 'block';
                            input.classList.add('field-invalid');
                          } else if (input !== null && validateInput(input) && isRequired !== null) {
                            container.querySelector('.scfValidator').style.display = 'none';
                            input.classList.remove('field-invalid');
                          }
                        });
                      } else if ((addressField.value === '' || cityField.value === '') && data8Dropdown !== null) {
                        var dropdownOKButton = data8Dropdown.querySelector('.data8-postcodelookup-ok');
                        dropdownOKButton.addEventListener('click', function () {
                          button.click();
                        });
                      } else {
                        addressField.readOnly = true;
                        cityField.readOnly = true;
                        addressField.classList.add('input-readonly');
                        cityField.classList.add('input-readonly');
                        button.click();
                      }
                    }, 500);
                    e.preventDefault();
                  });
                }
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        };
        var observer = new MutationObserver(callback);
        observer.observe(fieldSet, config);
      }
    });
    scfFooterBorder.classList.add('field-hidden');
    scfSubmitButtonBorder.classList.add('field-hidden');
    var submit = form.querySelector('.scfSubmitButton');
    submit.addEventListener('click', function (e) {
      var fieldFinal = form.querySelector('.field-final');
      var isValid = true;
      var inputContainers = fieldFinal.querySelectorAll('.scfSectionContent > div');
      inputContainers.forEach(function (container) {
        var isRequired = container.querySelector('.scfRequired');
        if (container.classList.contains('scfRadioButtonListBorder') && isRequired !== null) {
          var radioInputs = container.querySelectorAll('input[type=radio]');
          var isRadiosValid = false;
          radioInputs.forEach(function (radio) {
            if (radio.checked) {
              isRadiosValid = true;
            }
          });
          if (!isRadiosValid) {
            container.classList.add('radios-invalid');
            isValid = false;
          } else {
            container.classList.remove('radios-invalid');
          }
        } else {
          var input = container.querySelector('input');
          if (input !== null && !validateInput(input) && isRequired !== null) {
            var scfValidator = container.querySelector('.scfValidator');
            if (scfValidator !== null) {
              scfValidator.style.display = 'block';
            }
            isValid = false;
            input.classList.add('field-invalid');
          } else if (input !== null && validateInput(input) && isRequired !== null) {
            input.classList.remove('field-invalid');
          }
        }
      });
      if (isValid === false) {
        e.preventDefault();
      }
    });
  }
});