import PropTypes from 'prop-types';
export var isScrolled = PropTypes.bool;
export var isDesktop = PropTypes.bool;
export var bigLogo = PropTypes.oneOfType([PropTypes.shape({
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
}).isRequired, PropTypes.oneOf([null]).isRequired]);
export var smallLogo = PropTypes.oneOfType([PropTypes.shape({
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
}).isRequired, PropTypes.oneOf([null]).isRequired]);
export var search = PropTypes.oneOfType([PropTypes.shape({
  searchUrl: PropTypes.string.isRequired
}).isRequired, PropTypes.oneOf([null]).isRequired]);
export var header = PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.oneOf([null]).isRequired]);
export var headerCta = PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.oneOf([null]).isRequired]);
export var languageSelector = PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.oneOf([null]).isRequired]);
export var megaMenu = PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.oneOf([null]).isRequired]);
export var dictionary = PropTypes.shape({
  Navigation: PropTypes.shape({
    EnterSearchTerm: PropTypes.string.isRequired,
    Cancel: PropTypes.string.isRequired,
    SearchFor: PropTypes.string.isRequired,
    SearchSubmit: PropTypes.string.isRequired,
    SelectLanguage: PropTypes.string.isRequired
  })
});