import * as consts from './constants';
var pushData = function pushData(data) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};
export var trackScreeningTestAnswers = function trackScreeningTestAnswers(componentType, questionNo, answer) {
  var data = {
    event: consts.FW_GA_EVENT_NAME,
    eventCategory: componentType,
    eventAction: consts.FW_QUESTION_NO(questionNo),
    eventLabel: answer
  };
  pushData(data);
};
export var trackScreeningTestResult = function trackScreeningTestResult(componentType, result) {
  var isPosted = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var data = {
    event: consts.FW_GA_EVENT_NAME,
    eventCategory: componentType,
    eventAction: isPosted ? consts.FW_SEND_CONTACT : consts.FW_RESULT,
    eventLabel: result
  };
  pushData(data);
};
export var trackFormWizardAnswers = function trackFormWizardAnswers(answerNo) {
  var data = {
    event: consts.FW_GA_EVENT_NAME,
    eventCategory: consts.FW_GA_CATEGORY_AUDI,
    eventAction: consts.FW_STEP_NO(answerNo)
  };
  pushData(data);
};