"undefined" == typeof data8 && (window.data8 = function () {}), "undefined" == typeof d8jQuery && ("undefined" != typeof jQuery ? window.d8jQuery = jQuery : console.log("jQuery is required for Data8 Ajax Address Validation")), function (t) {
  var e = "https://webservices.data-8.co.uk";
  data8.postcodeLookupButton = function (t, e) {
    this.fields = t, this.options = e, this.validate(), this.valid && this.loadAjax();
  }, data8.postcodeLookupButton.prototype.getElement = function (t) {
    var e = document.getElementById(t);
    if (!e) {
      var s = document.getElementsByName(t);
      s && s.length && (e = s[s.length - 1]);
    }
    return e;
  }, data8.postcodeLookupButton.prototype.getElementByJquerySelector = function (e) {
    return t(e).get(0);
  }, data8.postcodeLookupButton.prototype.validate = function () {
    if (this.valid = !0, !this.fields) return void (this.valid = !1);
    if (!this.options) return void (this.valid = !1);
    if (!this.options.ajaxKey && "undefined" == typeof data8.addresscapture) return void (this.valid = !1);
    if (!this.options.license) return void (this.valid = !1);
    for (var t = 0; t < this.fields.length; t++) if (!(this.fields[t].jQuerySelector && this.getElementByJquerySelector(this.fields[t].jQuerySelector) || this.fields[t].element && this.getElement(this.fields[t].element))) return void (this.valid = !1);
    for (var e = 0; e < this.fields.length; e++) if ("organisation" !== this.fields[e].field && "line1" !== this.fields[e].field && "line2" !== this.fields[e].field && "line3" !== this.fields[e].field && "line4" !== this.fields[e].field && "line5" !== this.fields[e].field && "line6" !== this.fields[e].field && "town" !== this.fields[e].field && "county" !== this.fields[e].field && "postcode" !== this.fields[e].field && "country" !== this.fields[e].field) return void (this.valid = !1);
    for (var s = !1, i = !1, o = 0; o < this.fields.length && (!s || !i); o++) "line1" === this.fields[o].field ? i = !0 : "postcode" === this.fields[o].field && (s = !0);
    return s && i ? (this.options.findLabel || (this.options.findLabel = "Find"), this.options.okLabel || (this.options.okLabel = "OK"), this.options.cancelLabel || (this.options.cancelLabel = "Cancel"), !this.options.bootstrapUsed || "string" == typeof this.options.bootstrapUsed && "true" !== this.options.bootstrapUsed.toLowerCase() ? this.options.bootstrapUsed = !1 : this.options.bootstrapUsed = !0, void (this.options.bootstrapButton || (this.options.bootstrapButton = "btn-primary"))) : void (this.valid = !1);
  }, data8.postcodeLookupButton.prototype.selectAddress = function (t) {
    for (var e = 0; e < this.fields.length; e++) {
      var s;
      s = this.fields[e].jQuerySelector ? this.getElementByJquerySelector(this.fields[e].jQuerySelector) : this.getElement(this.fields[e].element);
      var i;
      if (i = "organisation" === this.fields[e].field ? this.toProperCase(t.RawAddress.Organisation) : "line1" === this.fields[e].field ? t.Address.Lines[0] : "line2" === this.fields[e].field ? t.Address.Lines[1] : "line3" === this.fields[e].field ? t.Address.Lines[2] : "line4" === this.fields[e].field ? t.Address.Lines[3] : "line5" === this.fields[e].field ? t.Address.Lines[4] : "line6" === this.fields[e].field ? t.Address.Lines[5] : "town" === this.fields[e].field ? t.Address.Lines[t.Address.Lines.length - 3] : "county" === this.fields[e].field ? t.Address.Lines[t.Address.Lines.length - 2] : "postcode" === this.fields[e].field ? t.Address.Lines[t.Address.Lines.length - 1] : s.value, e > 0 && (!this.fields[e - 1].jQuerySelector && this.fields[e - 1].element === this.fields[e].element || this.fields[e - 1].jQuerySelector && this.fields[e - 1].jQuerySelector === this.fields[e].jQuerySelector) && s.value && (i = s.value + "\r\n" + i), s.value = i, document.createEventObject) s.fireEvent("onchange");else {
        var o = document.createEvent("HTMLEvents");
        o.initEvent("change", !1, !0), s.dispatchEvent(o);
      }
    }
  }, data8.postcodeLookupButton.prototype.createButton = function (t) {
    var e = document.createElement("input");
    return e.type = "button", e.value = t, e;
  }, data8.postcodeLookupButton.prototype.insertButton = function () {
    for (var e = 0; e < this.fields.length; e++) if ("postcode" === this.fields[e].field) {
      var s;
      if (s = this.fields[e].jQuerySelector ? this.getElementByJquerySelector(this.fields[e].jQuerySelector) : this.getElement(this.fields[e].element), this.options.bootstrapUsed && (t(s).parent().hasClass("form-group") && t(s).wrap("<div class='input-group'></div>"), t(s).parent().hasClass("input-group"))) {
        t(t("<div class='input-group-btn'></div>").insertAfter(s)).append(this.button);
        break;
      }
      t(this.button).insertAfter(s);
      break;
    }
  }, data8.postcodeLookupButton.prototype.getLineCount = function () {
    if ("undefined" == typeof this.lineCount) {
      this.lineCount = 0;
      for (var t = 0; t < this.fields.length; t++) 0 === this.fields[t].field.indexOf("line") ? this.lineCount++ : "town" === this.fields[t].field ? this.lineCount++ : "county" === this.fields[t].field && this.lineCount++;
    }
    return this.lineCount;
  }, data8.postcodeLookupButton.prototype.usesFixedTownCounty = function () {
    if ("undefined" == typeof this.fixedTownCounty) {
      this.fixedTownCounty = !1;
      for (var t = 0; t < this.fields.length; t++) if ("town" === this.fields[t].field || "county" === this.fields[t].field) {
        this.fixedTownCounty = !0;
        break;
      }
    }
    return this.fixedTownCounty;
  }, data8.postcodeLookupButton.prototype.usesOrganisation = function () {
    if ("undefined" == typeof this.organisation) {
      this.organisation = !1;
      for (var t = 0; t < this.fields.length; t++) if ("organisation" === this.fields[t].field) {
        this.organisation = !0;
        break;
      }
    }
    return this.organisation;
  }, data8.postcodeLookupButton.prototype.showAddressList = function (e) {
    for (; this.list.options.length > 0;) this.list.options[this.list.options.length - 1] = null;
    for (var s = 0; s < e.length; s++) {
      var i = document.createElement("option");
      i.text = this.getAddressText(e[s]), i.address = e[s];
      try {
        this.list.add(i, this.list.options[null]);
      } catch (o) {
        this.list.add(i, null);
      }
    }
    this.list.multiple = !1, this.list.selectedIndex = 0;
    var n = this;
    if (this.list.applySelectedAddress = function () {
      var t = e[n.list.selectedIndex];
      n.selectAddress(t);
    }, this.options.bootstrapUsed) t(this.addressModal).modal("show");else {
      for (var d = null, l = 0; l < this.fields.length; l++) if ("postcode" === this.fields[l].field) {
        d = this.fields[l].jQuerySelector ? this.getElementByJquerySelector(this.fields[l].jQuerySelector) : this.getElement(this.fields[l].element);
        break;
      }
      if (!d) return;
      var a = t(d),
        r = a.width(),
        u = a.height(),
        h = a.offset(),
        p = t(this.dropdown);
      p.insertAfter(this.button), p.css({
        top: h.top + u + "px",
        left: h.left + "px"
      }), this.list.style.minWidth = r + "px", p.show("fast"), this.list.focus();
    }
  }, data8.postcodeLookupButton.prototype.show = function () {
    if (this.valid) {
      if (this.shown = !0, this.button) return void (this.button.style.display = this.buttonDisplay);
      this.options.bootstrapUsed ? this.createBsUI() : this.createUI();
      for (var e = this, s = 0; s < this.fields.length; s++) if ("postcode" === this.fields[s].field) {
        var i = null;
        if (i = this.fields[s].jQuerySelector ? this.getElementByJquerySelector(this.fields[s].jQuerySelector) : this.getElement(this.fields[s].element), !i) continue;
        this.button = this.createButton(this.options.findLabel), this.button.className = "postcodeLookup", this.options.bootstrapUsed && (this.button.className += " btn " + this.options.bootstrapButton), this.insertButton(), this.buttonDisplay = this.button.style.display, t(this.button).click(function () {
          var t = new data8.addresscapture(),
            s = [{
              name: "MaxLines",
              value: e.getLineCount()
            }, {
              name: "FixTownCounty",
              value: e.usesFixedTownCounty()
            }, {
              name: "Formatter",
              value: e.usesOrganisation() ? "NoOrganisationFormatter" : "DefaultFormatter"
            }, {
              name: "IncludeNYB",
              value: e.options.includeNYB ? "true" : "false"
            }, {
              name: "IncludeMR",
              value: e.options.includeMR ? "true" : "false"
            }];
          t.getfulladdress(e.options.license, i.value, "", s, function (t) {
            t.Status.Success ? 0 === t.Results.length ? alert("Postcode not recognised") : 1 === t.Results.length ? e.selectAddress(t.Results[0]) : e.showAddressList(t.Results) : alert(t.Status.ErrorMessage);
          });
        });
      } else if ("country" === this.fields[s].field && this.options.expectedCountry) {
        var o = null;
        if (o = t(this.fields[s].jQuerySelector ? this.fields[s].jQuerySelector : this.getElement(this.fields[s].element)), !o) continue;
        var n = this.getCountry(o);
        n !== this.options.expectedCountry && this.hide(!0), o.change(function () {
          var s = t(this);
          e.getCountry(s) !== e.options.expectedCountry ? e.hide(!0) : e.shown && e.show();
        });
      }
    }
  }, data8.postcodeLookupButton.prototype.getCountry = function (t) {
    return t.val();
  }, data8.postcodeLookupButton.prototype.hide = function (t) {
    this.button && (this.button.style.display = "none", this.shown = t);
  }, data8.postcodeLookupButton.prototype.createBsUI = function () {
    this.addressModal = document.createElement("div"), this.addressModal.className = "modal fade", this.addressModal.tabIndex = -1, this.addressModal.role = "dialog", this.addressModal.setAttribute("aria-labelledby", "addModalLabel"), document.body.appendChild(this.addressModal);
    var t = document.createElement("div");
    t.className = "modal-dialog", t.role = "document", this.addressModal.appendChild(t);
    var e = document.createElement("div");
    e.className = "modal-content", t.appendChild(e);
    var s = document.createElement("div");
    s.className = "modal-header", e.appendChild(s);
    var i = document.createElement("button");
    i.className = "close", i.type = "button", i.setAttribute("data-dismiss", "modal"), i.setAttribute("aria-label", "Close");
    var o = document.createElement("span");
    o.setAttribute("aria-hidden", "true"), o.innerHTML = "&times;", i.appendChild(o), s.appendChild(i);
    var n = document.createElement("h4");
    n.className = "model-title", n.innerHTML = "Select Address", s.appendChild(n);
    var d = document.createElement("div");
    d.className = "modal-body", e.appendChild(d);
    var l = document.createElement("div");
    l.className = "form-group", d.appendChild(l), this.list = document.createElement("select"), this.list.size = 10, this.list.className = "form-control", l.appendChild(this.list);
    var a = document.createElement("div");
    a.className = "modal-footer", e.appendChild(a);
    var r = document.createElement("button");
    r.className = "btn btn-primary", r.innerHTML = "OK", r.type = "button", r.setAttribute("data-dismiss", "modal"), a.appendChild(r);
    var u = document.createElement("button");
    u.className = "btn btn-default", u.innerHTML = "Cancel", u.type = "button", u.setAttribute("data-dismiss", "modal"), a.appendChild(u), this.attachEvents(r);
  }, data8.postcodeLookupButton.prototype.attachEvents = function (e) {
    var s,
      i = !1,
      o = this;
    t(this.list).blur(function () {
      s = function s() {
        i ? (i = !1, t(o.list).focus()) : null != document.activeElement && document.activeElement === e || t(o.dropdown).hide("fast");
      }, setTimeout(s, 100);
    }).dblclick(function () {
      t(e).click();
    }).click(function () {
      var t = navigator.userAgent;
      (/iPad/i.test(t) || /iPhone/i.test(t)) && (i = !0);
    }), t(e).click(function () {
      o.list.applySelectedAddress(), t(o.dropdown).hide("fast");
    });
  }, data8.postcodeLookupButton.prototype.createUI = function () {
    this.dropdown = document.createElement("div"), this.dropdown.style.position = "absolute", this.dropdown.style.display = "none", this.dropdown.style.backgroundColor = "#FFFFFF", this.dropdown.style.padding = "1px", this.dropdown.style.zIndex = "50102", this.dropdown.className = "data8-postcodelookup-dropdown", document.body.appendChild(this.dropdown), this.list = document.createElement("select"), this.list.size = 10, this.list.className = "data8-postcodelookup-list", this.dropdown.appendChild(this.list);
    var t = document.createElement("div");
    t.style.textAlign = "right", t.style.marginTop = "1em", this.dropdown.appendChild(t);
    var e = this.createButton(this.options.okLabel);
    e.className = "data8-postcodelookup-ok", t.appendChild(e);
    var s = this.createButton(this.options.cancelLabel);
    s.className = "data8-postcodelookup-cancel", t.appendChild(s), this.attachEvents(e);
  }, data8.postcodeLookupButton.prototype.loadAjax = function () {
    if ("undefined" == typeof data8.addresscapture) {
      var t = document.createElement("script");
      t.type = "text/javascript", t.src = e + "/javascript/loader.ashx?key=" + this.options.ajaxKey + "&load=AddressCapture", document.getElementsByTagName("head")[0].appendChild(t);
    }
  }, data8.postcodeLookupButton.prototype.getAddressText = function (t) {
    var e = "";
    this.usesOrganisation() && t.RawAddress.Organisation && (e = this.toProperCase(t.RawAddress.Organisation));
    for (var s = 0; s < t.Address.Lines.length; s++) t.Address.Lines[s] && (e && (e += ", "), e += t.Address.Lines[s]);
    return e;
  }, data8.postcodeLookupButton.prototype.toProperCase = function (t) {
    return t.toLowerCase().replace(/^(.)|\s(.)/g, function (t) {
      return t.toUpperCase();
    });
  };
}(d8jQuery);