(function ($) {
  window.DGS.NewsListSpotConfig = {
    equalHeaderHeight: false,
    equalLocationHeight: false,
    equalDateHeight: false,
    equalTextHeight: false
  };
  window.DGS.OnLoad.getInstance().register(function () {
    var c = 0;
    $('.news-list-spot').each(function () {
      var $this = $(this);
      if (window.DGS.NewsListSpotConfig.equalHeaderHeight) window.DGS.EqualHeight.getInstance().register($('.news-header', $this), true);
      if (window.DGS.NewsListSpotConfig.equalLocationHeight) window.DGS.EqualHeight.getInstance().register($('.news-location', $this), true);
      if (window.DGS.NewsListSpotConfig.equalDateHeight) window.DGS.EqualHeight.getInstance().register($('.news-date', $this), true);
      if (window.DGS.NewsListSpotConfig.equalTextHeight) window.DGS.EqualHeight.getInstance().register($('.news-text', $this), false);
      window.DGS.EqualHeight.getInstance().register($('.news-link', $this), false);
      window.DGS.DrawSeparators.getInstance().register($('.component-content ul li', $this), "news-list-spot-style".concat(c++));
    });
  });
})(jQuery);