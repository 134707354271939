/* eslint-disable */
(function ($) {
  var selectors = {
    scfForm: '.scfForm',
    scfTitleBorder: '.scfTitleBorder',
    scfIntroBorder: '.scfIntroBorder',
    scfValidationSummary: '.scfValidationSummary',
    scfSubmitSummary: '.scfSubmitSummary',
    scfSectionBorder: '[class*="scf"][class*="SectionBorder"]',
    scfSectionLegend: '.scfSectionLegend',
    scfSectionContent: '.scfSectionContent',
    scfEntry: '[class*="name."]',
    scfLabel: '[class*="scf"][class*="Label"]',
    scfGeneralPanel: '[class*="scf"][class*="GeneralPanel"]',
    scfUsefulInfo: '[class*="scf"][class*="UsefulInfo"]',
    scfValidator: '.scfValidator',
    scfRequired: '[class*="scf"][class*="Required"]',
    scfFooterBorder: '.scfFooterBorder',
    scfSubmitButtonBorder: '.scfSubmitButtonBorder',
    scfValidatorRequired: '.scfValidatorRequired'
  };
  var methods = {
    init: function init(options) {
      var settings = $.extend({}, $.fn.wffm.defaults, options);
      if (settings.showValidatorsOnErrors) {
        // SHOW validator hints when field has error
        getEntries(this).each(function () {
          if (hasErrors($(this))) {
            $(selectors.scfValidator, $(this)).show();
          }
        });
      }
      if (settings.moveAsteriskToLabel) {
        // MOVE scfRequired elements to label
        getEntries(this).each(function () {
          var $this = $(this),
            $label = getLabel($this),
            $required = getRequired($this);
          // MOVE scfRequired elements to label
          if ($required.length) {
            if ($label.text().indexOf('*') < 0) {
              // prevents double asterix
              $label.append($required);
            }
          } else {
            $label.append('<span class="scfNotRequired">&nbsp;</span>');
          }
        });
      }
      if (getValidationSummaryItems(this).length > settings.maxEntriesInValidatorSummary) {
        // LIMIT number of items in validation summary
        $(selectors.scfValidationSummary, this).find("li:nth-child(1n + ".concat(settings.maxEntriesInValidatorSummary + 1, ")")).hide();
        $(selectors.scfValidationSummary, this).find("li:nth-child(1n + ".concat(settings.maxEntriesInValidatorSummary, ")")).text('...');
      }
      if (settings.niceSelectClass != null) {
        // ADD custom classes to jquery nice select - supports string and array of strings
        if (settings.niceSelectClass.constructor === Array) {
          settings.niceSelectClass.forEach(function (customClass) {
            $('.nice-select', this).addClass(customClass);
          });
        } else {
          $('.nice-select', this).addClass(settings.niceSelectClass);
        }
      }
      if (settings.usePhoneTypeInput) {
        $('.scfTelephoneTextBox', this).each(function () {
          $(this).attr('type', 'tel');
        });
      }

      // CLEAN UP OF MARKUP
      // HIDE empty scf***UsefulInfo tags - cleans i
      $(selectors.scfUsefulInfo, this).each(function () {
        if ($(this).text().trim() === '') {
          $(this).hide();
        }
      });
      // CLEAN UP OF MARKUP - end
    },
    entries: function entries() {
      return getEntries(this);
    },
    label: function label() {
      return getLabel(this);
    },
    required: function required() {
      return getRequired(this);
    },
    usefulInfo: function usefulInfo() {
      return $(selectors.scfUsefulInfo, this);
    },
    hasError: function hasError() {
      return hasErrors(this);
    },
    getElementsFromQuery: function getElementsFromQuery(query, context) {
      return $(query, context);
    }
  };
  $.fn.wffm = function (options) {
    methods.init.apply(this, [options]);
    var form = this;
    return {
      scfForm: function scfForm() {
        return methods.getElementsFromQuery(selectors.scfForm, form);
      },
      scfTitleBorder: function scfTitleBorder() {
        return methods.getElementsFromQuery(selectors.scfTitleBorder, form);
      },
      scfIntroBorder: function scfIntroBorder() {
        return methods.getElementsFromQuery(selectors.scfIntroBorder, form);
      },
      scfValidationSummary: function scfValidationSummary() {
        return methods.getElementsFromQuery(selectors.scfValidationSummary, form);
      },
      scfSubmitSummary: function scfSubmitSummary() {
        return methods.getElementsFromQuery(selectors.scfSubmitSummary, form);
      },
      scfSectionBorder: function scfSectionBorder() {
        return methods.getElementsFromQuery(selectors.scfSectionBorder, form);
      },
      scfSectionLegend: function scfSectionLegend() {
        return methods.getElementsFromQuery(selectors.scfSectionLegend, form);
      },
      scfSectionContent: function scfSectionContent() {
        return methods.getElementsFromQuery(selectors.scfSectionContent, form);
      },
      scfEntry: function scfEntry() {
        return methods.getElementsFromQuery(selectors.scfEntry, form);
      },
      scfLabel: function scfLabel() {
        return methods.getElementsFromQuery(selectors.scfLabel, form);
      },
      scfGeneralPanel: function scfGeneralPanel() {
        return methods.getElementsFromQuery(selectors.scfGeneralPanel, form);
      },
      scfUsefulInfo: function scfUsefulInfo() {
        return methods.getElementsFromQuery(selectors.scfUsefulInfo, form);
      },
      scfValidator: function scfValidator() {
        return methods.getElementsFromQuery(selectors.scfValidator, form);
      },
      scfValidatorRequired: function scfValidatorRequired() {
        return methods.getElementsFromQuery(selectors.scfValidatorRequired, form);
      },
      scfRequired: function scfRequired() {
        return methods.getElementsFromQuery(selectors.scfRequired, form);
      },
      scfFooterBorder: function scfFooterBorder() {
        return methods.getElementsFromQuery(selectors.scfFooterBorder, form);
      },
      scfSubmitButtonBorder: function scfSubmitButtonBorder() {
        return methods.getElementsFromQuery(selectors.scfSubmitButtonBorder, form);
      }
    };
  };

  // Public defaults
  $.fn.wffm.defaults = {
    showValidatorsOnErrors: true,
    moveAsteriskToLabel: false,
    maxEntriesInValidatorSummary: Number.MAX_SAFE_INTEGER,
    niceSelectClass: null,
    usePhoneTypeInput: false
  };

  // Private methods

  function getEntries($form) {
    var $elements = $([]);
    $(selectors.scfGeneralPanel, $form).each(function () {
      $elements = $elements.add($(this).parent());
    });
    return $elements;
  }
  function hasErrors($formOrEntry) {
    return $formOrEntry.find(selectors.scfValidatorRequired).length > 0;
  }
  function getLabel($formOrEntry) {
    return $(selectors.scfLabel, $formOrEntry);
  }
  function getRequired($formOrEntry) {
    return $(selectors.scfRequired, $formOrEntry);
  }
  function getValidationSummaryItems($form) {
    return $(selectors.scfValidationSummary, $form).find('li');
  }
})(jQuery);