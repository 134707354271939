(function ($) {
  window.DGS.CenterSpotConfig = {
    mapBrowserWidth: false
  };
  window.DGS.OnLoad.getInstance().register(function () {
    // extra div to style regions overview:
    $('.center-region-item').wrapAll('<div class="region-list">');
    $('.center-region-item').css('cursor', 'pointer');
    function scrollToDiv(element, navheight) {
      var offsetTop = element.offset().top;
      var totalScroll = offsetTop - navheight;
      $('body,html').animate({
        scrollTop: totalScroll
      }, 500);
    }
    $('.center-region-item').click(function () {
      var el = $(this).attr('data-anchorregion');
      var elWrapped = $("[id='".concat(el, "']"));
      scrollToDiv(elWrapped, 60);
      return false;
    });
    $('.center-region-item').hover(function () {
      var reg = $(this).attr('data-mapcoords');
      var myhash = '#';
      var hashreg = myhash.concat(reg);
      $(hashreg).css('fill', '#A7D1D3'); // think about making the fill colors configurable via Sitecore, enabling customisation for other sites
    }, function () {
      var reg = $(this).attr('data-mapcoords');
      var myhash = '#';
      var hashreg = myhash.concat(reg);
      $(hashreg).css('fill', '#19AD97');
    });
  });
})(jQuery);