(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.component.blog').each(function () {
      // Push the page below the sticky top navigation
      if (!$('body').hasClass("flex-header")) {
        $('#wrapper #content').css('padding-top', $('#wrapper #header').outerHeight());
      }
      $('.blog-reading-time').wrap('<div class="blog-time-wrap"></div>');
      $('.blog-date', $(this)).insertBefore($('.blog-reading-time', $(this)));
      $('.blog-author').wrap('<div class="blog-author-wrap"></div>');
      if ($('.blog--two-col').length) {
        $('.blog-image', $(this)).addClass('blog-image--top').insertBefore('.blog--two-col');
        // $(".blog-image").insertBefore(".blog--two-col").wrap("<div class='component'></div>");
      }
    });
  });
})(jQuery);