(function ($) {
  window.DGS.Components = window.DGS.Components || {};
  window.DGS.Components.ImageSpot = window.DGS.Components.ImageSpot || {};
  window.DGS.Components.ImageSpot.ShouldEqualizeHeader = function ($spot) {
    return true;
  };
  window.DGS.Components.ImageSpot.ShouldEqualizeText = function ($spot) {
    return true;
  };
  window.DGS.OnLoad.getInstance().register(function () {
    $('.image-spot:not(.accordion):not(.as-slider):not(.flex-layout)').each(function () {
      if (window.DGS.Components.ImageSpot.ShouldEqualizeHeader($(this))) {
        window.DGS.EqualHeight.getInstance().register($('.image-spot-element-header', $(this)));
      }
      if (window.DGS.Components.ImageSpot.ShouldEqualizeText($(this))) {
        window.DGS.EqualHeight.getInstance().register($('.image-spot-element-text', $(this)));
      }
    });
    window.DGS.EqualHeight.getInstance().register($('.image-spot-element-header', $(this)));
    window.DGS.EqualHeight.getInstance().register($('.image-spot-element-text', $(this)));
    function onAddContentClick(event, data) {
      var $this = $(this),
        $ul = $this.closest('ul'),
        $lis = $('.image-spot-element', $ul),
        $li = $this.closest('.image-spot-element'),
        liIndex = $li.index(),
        index = $li.data('context-content-index'),
        division = $li.data('context-content-division'),
        $contentlist = $('.image-spot-element-context', $ul),
        $content = $('.image-spot-element-context', $li),
        divisionWidth = 100 / division,
        selectorleft = divisionWidth * liIndex + divisionWidth / 2,
        $componentContent = $this.closest('.component-content'),
        $container = $('.context-container', $componentContent),
        isStacked = $lis.length == 1,
        $prevLi;
      event.preventDefault();
      if ($content) {
        $lis.each(function () {
          var $this = $(this);
          if ($prevLi != null) {
            if ($this.offset().top > $prevLi.offset().top) {
              isStacked = true;
            }
          }
          $prevLi = $this;
        });
        if (!$container.length) {
          $container = $('<div class="context-container"></div>');
          $componentContent.append($container);
        }
        $lis.removeClass('active');
        if (!isStacked) {
          if ($container.data('context-content-index') != index) {
            $container.slideUp(0, function () {
              $li.addClass('active');
              $container.html($content.children().clone(true));
              // get selectors border width
              var sbw = parseFloat($('.image-spot-element-context-selector', $container).css('border-left-width')),
                // get active li's padding-left and padding-right
                apl = parseFloat($li.css('padding-left')) / 2,
                apr = parseFloat($li.css('padding-right')) / 2,
                // get context-container width
                ccw = $container.outerWidth(),
                // calculate percent
                percent = (selectorleft / 100 * ccw - sbw + apl - apr) / ccw;
              $('.image-spot-element-context-selector', $container).css('left', "".concat(percent * 100, "%"));
              if (data != null && !data.animate) {
                $container.show();
              } else {
                $container.slideDown(0);
              }
            });
            $container.data('context-content-index', index);
          } else {
            $container.slideUp(0);
            $container.data('context-content-index', '-1');
          }
        } else {
          if ($container.data('context-content-index') != index) {
            $container.css('display', 'block');
            $container.data('context-content-index', index);
            $li.addClass('active');
          } else {
            $container.css('display', 'none');
            $container.data('context-content-index', '-1');
          }
          $container.html($content.children().clone(true));
          $contentlist.each(function () {
            if (this != $content[0]) {
              $(this).hide('fast');
            } else {
              $content.slideToggle('fast', function () {
                $('html, body').animate({
                  scrollTop: $li.offset().top - 20
                }, 'slow');
              });
            }
          });
          $('.image-spot-element-context-selector', $container).css('left', "".concat(selectorleft, "%"));
        }
        //                $('.image-spot-element-context-selector', $content).css('left', '50%');
      }
    }
    $('.image-spot:not(.accordion):not(.flex-layout)').each(function () {
      var $imageSpot = $(this),
        index = 0,
        numLi = $('.image-spot-element', $imageSpot).length;
      $('.image-spot-element', $imageSpot).each(function () {
        var $this = $(this),
          $link = $('.image-spot-element-link a', $this),
          href = $link.attr('href'),
          target = $link.attr('target'),
          title = $link.attr('title') != undefined ? $link.attr('title') : '',
          $context = $('.image-spot-element-context', $this),
          $image = $('.image-spot-element-image', $(this));
        if ($context.length) {
          href = '#';
          $link.attr('href', href);
          $this.data('context-content-index', index);
          $this.data('context-content-division', numLi);
          $image.wrap("<a href=\"".concat(href, "\"></a>"));
          $image.parent().on('click', onAddContentClick);
          $link.on('click', onAddContentClick);
        } else if (href != undefined && href != '') {
          $image.wrap("<a href=\"".concat(href, "\" target=\"").concat(target, "\" title=\"").concat(title.replace(/\</g, '[').replace(/\>/g, ']').replace(/"/g, "'"), "\"></a>"));
        }
        index++;
      });
    });
    $('.component.image-spot.accordion .image-spot-element').each(function () {
      var $this = $(this),
        $header = $('.image-spot-element-header', $this),
        $image = $('.image-spot-element-image', $this),
        $text = $('.image-spot-element-text', $this),
        $contentWrapper = $('<div class="image-spot-element-content"></div>');
      $contentWrapper.insertAfter($header);
      $contentWrapper.append($('<div class="spacer"></div>'));
      $contentWrapper.append($image);
      $contentWrapper.append($text);
      $contentWrapper.append($('<div class="mask"></div>'));
      $header.click(function () {
        var openThis = true;
        if ($this.hasClass('open')) {
          openThis = false;
        }
        $('.component.image-spot.accordion .image-spot-element').removeClass('open');
        if (openThis) $this.addClass('open');
      });
    });
    window.DGS.Resizer.getInstance().register(function () {
      $('.image-spot:not(.flex-layout)').each(function () {
        var $this = $(this),
          $activeLi = $('li.active', $this),
          $content = $('.image-spot-element-context', $activeLi);
        if ($content.length) {
          $content.show();
        }
      });
      $('.image-spot.accordion .image-spot-element').each(function () {
        var $this = $(this),
          $container = $('.image-spot-element-content', $this),
          $spacer = $('.spacer', $this),
          $img = $('.image-spot-element-image', $this),
          $header = $('.image-spot-element-header', $this),
          $text = $('.image-spot-element-text', $this),
          h;
        $container.outerWidth($header.outerWidth());
        $spacer.height(0);
        h = Math.max(0, $container.outerHeight() - $img.outerHeight());
        $spacer.height(h);
        while (h > 0 && $img[0].getBoundingClientRect().bottom > $text[0].getBoundingClientRect().bottom + 16) {
          $spacer.height(--h);
        }
        if ($('img', $img).length < 1) {
          $img.hide();
        }
      });
    });
  });
})(jQuery);
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.component.image-spot.square-spot').each(function (i) {
      var $thisSquareSpot = $(this);
      var $thisImageSpotElementLink = $thisSquareSpot.find('.image-spot-element-link');
      $thisImageSpotElementLink.each(function () {
        var $thisLink = $(this);
        var $deepestChild = $thisLink.siblings('.image-spot-element-header').children(),
          $next = $deepestChild;

        // find the last child of image spot element header
        while ($next.length) {
          $deepestChild = $next;
          $next = $next.children();
        }
        $deepestChild.addClass('clickable').on('click', function () {
          location.href = $thisLink.find('a').attr('href');
        });
      });
    });
    $('.component.image-spot.staff.image-spot-align-left').each(function () {
      if (window.matchMedia('(min-width: 769px)').matches) {
        var rows = $(this).find('.image-spot-elements');
        var elements = $(this).find('.image-spot-element');
        var elementsCounter = 0;
        var elementWidth = 0;
        rows.each(function () {
          var length = $(this).children('li').length;
          if (length > elementsCounter) {
            elementsCounter = length;
          }
        });
        elementWidth = 100 / elementsCounter;
        elements.each(function () {
          $(this).attr('style', "width: ".concat(elementWidth, "%"));
        });
      }
    });
    $('.component.as-tab.image-spot, .component.as-faq.image-spot').each(function () {
      var $tabs = $(this).find('.image-spot-elements'),
        $firstTab = $tabs.children('li:first-child'),
        $componentContent = $firstTab.closest('.component-content'),
        $container = $('.context-container', $componentContent),
        $content = $('.image-spot-element-context', $firstTab);

      // by default display content opf the first tab
      if ($firstTab.length != 0) {
        $firstTab.addClass('active');
        if (!$container.length) {
          $container = $('<div class="context-container"></div>');
          $componentContent.append($container);
          $container.html($content.html());
        }
      }

      // append title and description to clickable tab
      $.each($tabs.children('.image-spot-element'), function () {
        var $link = $(this).children('a'),
          $header = $(this).children('.image-spot-element-header');
        $link.append($header);
      });
      window.DGS.Resizer.getInstance().register(function () {
        window.DGS.EqualHeight.getInstance().register($tabs.find('.image-spot-element-image'), false);

        // make tab heights equal for non mobile
        if ($(window).width() > 601) {
          // desktop
          window.DGS.EqualHeight.getInstance().register($tabs.children('.image-spot-element'), false);
        } else {
          $tabs.children('.image-spot-element').css('height', 'auto');
        }
      });
    });
    $('.component.image-spot.text-link-under-image .image-spot-element').each(function () {
      var $origLink = $('.image-spot-element-link', $(this));
      $(this).children('a').append($origLink);
    });
    $('.image-spot.as-slider').each(function () {
      var $timeInterval,
        $rotateSlides,
        $currentIndex = 0,
        $slides = $(this).find('.image-spot-elements'),
        $tabs,
        $tabsHtml = "<div class='slider-tabs'><div class='tabs-content'>",
        $overlayHtml = "<div class='slider-overlay'></div>",
        $timeInterval = -1,
        $initiaInterval = -1,
        $slideInterval = 12000;

      // move text inside a slide
      $.each($slides.children('.image-spot-element'), function () {
        var $imgContainer = $(this).find('.image-spot-element-image');
        // $imgContainer.unwrap();
        var $text = $(this).children('.image-spot-element-text');
        $tabsHtml += "<div class='slider-tab'></div>";
        $imgContainer.append($overlayHtml);
        $imgContainer.append($text);
        $imgContainer.find('.image-spot-element-text').wrap("<div class='slide-content'></div>");
      });
      $tabsHtml += '</div></div>';

      // create slider tabs
      $($tabsHtml).insertAfter($slides);
      $tabs = $(this).find('.slider-tabs');
      $rotateSlides = function $rotateSlides() {
        // hide old
        $slides.children('.image-spot-element.active-slide').fadeOut(700);
        setTimeout($markInactive, 300);
        setTimeout($setActive, 600);
        if ($currentIndex == $slides.children('.image-spot-element').length) {
          $currentIndex = 1;
        } else {
          $currentIndex++;
        }
      };
      var $markInactive = function $markInactive() {
        $(this).removeClass('active-slide');
        $tabs.find('.slider-tab.active-tab').removeClass('active-tab');
      };
      var $setActive = function $setActive() {
        // mark and show active
        $slides.children(".image-spot-element:nth-child(".concat($currentIndex, ")")).fadeIn(1200);
        setTimeout($markActive, 600);

        // set height
        $slides.height($slides.children(".image-spot-element:nth-child(".concat($currentIndex, ")")).height());
      };
      var $markActive = function $markActive() {
        $slides.children(".image-spot-element:nth-child(".concat($currentIndex, ")")).addClass('active-slide');
        $tabs.find(".slider-tab:nth-child(".concat($currentIndex, ")")).addClass('active-tab');
      };
      $('.slider-tab').click(function () {
        // stop the interval rotation
        window.clearInterval($timeInterval);
        $currentIndex = $tabs.find('.slider-tab').index($(this));
        $rotateSlides();
      });
      var initSlider = function initSlider() {
        $currentIndex = 0;
        window.clearInterval($timeInterval);

        // mark first as active
        $rotateSlides();

        // start interval rotation
        $timeInterval = setInterval($rotateSlides, $slideInterval);
      };
      initSlider();
      $(window).resize(function () {
        clearTimeout($initiaInterval);
        $initiaInterval = setTimeout(initSlider, 100);
      });
    });
    window.DGS.Resizer.getInstance().register(function () {
      $('.image-spot.square-spot').each(function () {
        var $this = $(this);
        // Set the height as like image spot element header
        $('.image-spot-element-link', $this).each(function () {
          $(this).height($(this).closest('li.image-spot-element').find('.image-spot-element-header').height());
        });
      });
    });
  });
})(jQuery);