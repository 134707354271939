(function ($) {
  window.DGS.GlobalSliderConfig.slideInterval = 10000;

  // React components render after main.js so using the line below will not work. Use 'document' instead.
  // window.DGS.OnLoad.getInstance().register(() => {}

  $(document).on('click', 'a[href^="#"]', function (e) {
    var anchorElem = $(this);
    if (anchorElem.attr('href') !== '#' && !anchorElem.attr('href').includes('#/')) {
      var targetElem = $(anchorElem.attr('href'))[0];
      if (targetElem) {
        e.preventDefault();
        $('html, body').animate({
          scrollTop: $(targetElem).offset().top - $('#header').outerHeight()
        }, 500);
      }
    }
  });
  var url = new URL(window.location.href);
  var params = new URLSearchParams(url.search.slice(1));
  if (params.get('type') !== null) {
    setTimeout(function () {
      params.delete('type');
      var newParams = params.toString() === '' ? '' : "?".concat(params.toString());
      var newUrl = "".concat(window.location.origin).concat(window.location.pathname).concat(newParams);
      history.pushState({}, null, newUrl);
    }, 3000);
  }
})(jQuery);