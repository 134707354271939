/**
 * Created by mady on 26-04-2017 as main.js for oticon.
 * modified by awst on 14-08-2018 to enable custom positioning of social sharing icons on retail sites
 * some parts originally concieved as a sort of plug-in where parameters such as layout can be changed.Only social sharing parameters remain here.
 *
 */
(function ($) {
  var socialSharingConfig = {
    layout: "static",
    //insertAfter: "#content",
    insertAfter: "#content .blog-content",
    serviceElementsHeaderClass: "service-elements-header",
    serviceElementClass: "service-element",
    shareWindowDimensions: "width=626,height=436"
  };
  window.DGS.OnLoad.getInstance().register(function () {
    if ($("#social-sharing").length) {
      $("#social-sharing").sharingTools({
        layout: socialSharingConfig.layout,
        insertAfter: socialSharingConfig.insertAfter,
        serviceElementsHeaderClass: socialSharingConfig.serviceElementsHeaderClass,
        serviceElementClass: socialSharingConfig.serviceElementClass,
        shareWindowDimensions: socialSharingConfig.shareWindowDimensions
      });
      //if social media icons are present add identifying class to cookie-warning for manipulation purposes
      $(".component.cookie-warning").addClass("so-me");
    }
  });
})(jQuery, window.DGS);