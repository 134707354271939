require('core-js');require('regenerator-runtime/runtime');// FOUNDATION
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
require('../shared/foundation/index');
require('../../../wdh-foundation/dgs-sharing/dgs-sharing.js');

// BASE
require('../shared/base/index');
require('../flex-layout/components/columns-grid/container/js/component-container');

// FEATURE
require('./feature/component-accordion-spot.js');
require('./feature/component-color-spot.js');
require('./feature/component-data-hierarchy.js');
require('./feature/component-data-hierarchy-appframe-component.js');
require('./feature/component-data-hierarchy-hubspot.js');
require('./feature/component-data-hierarchy-multi-step.js');
require('./feature/component-data-hierarchy-dataset.js');
require('./feature/component-iframe.js');
require('./feature/component-image-spot.js');
require('./feature/component-intro-banner.js');
require('./feature/component-lattice-spot.js');
require('./feature/component-news-list-spot.js');
require('./feature/component-product-tool.js');
require('./feature/component-search.js');
require('./feature/component-screening-test-spot.js');
require('./feature/component-spot-control-spot.js');
require('./feature/component-text-image-spot.js');
require('./feature/component-video-spot.js');
require('./feature/component-two-section-grid.js');
require('./feature/component-blog.js');
require('./feature/component-blog-categories.js');
require('./feature/component-blog-list.js');
require('./feature/component-blog-related.js');
require('./feature/center-regions-spot.js');
require('./feature/component-centre-spot/component-centre-spot.js');
require('./feature/component-color-selector-spot.js');
require('./feature/component-comments.js');
require('./feature/component-content.js');
require('./feature/component-cookies.js');
require('./feature/component-data-hierarchy-lexicon.js');
require('./feature/component-event-calendar.js');
require('./feature/component-form.js');
require('./feature/component-image-spot.js');
require('./feature/component-intro-banner.js');
require('./feature/language-selector-spot.js');
require('./feature/component-news-list-spot.js');
require('./feature/component-producttool.js');
require('./feature/redirect-notification-spot.js');
require('./feature/component-search.js');
require('./feature/component-testimonial-spot.js');
require('./feature/component-wffm.js');
require('./feature/component-geo-ip-prompt');
require('./feature/component-site-notification-spot.js');
require('./feature/component-hearing-test.js');
require('./feature/component-data8.js');
require('./feature/component-job-list-spot.js');
require('./feature/component-job-details.js');

// PROJECT
require('./project/custom-footer.js');
require('./project/main.js');
require('./project/dgs-sharing.js');
require('./project/modal-content.js');
require('./project/chat.js');
require('./project/code-snippet.js');
require('./project/masked-input.js');

// FLEX COMPONENTS
require('../flex-layout/components/columns-grid/two-columns/js/component-two-columns');
require('../flex-layout/components/columns-grid/three-columns/js/component-three-columns');
require('../flex-layout/components/intro-banner/js/component-intro-banner');
require('../flex-layout/components/rich-text/js/component-rich-text');
require('../flex-layout/components/header/js/component-header');
require('../flex-layout/components/form-wizard/js/form-wizard');
require('../flex-layout/components/form-wizard/js/wffm-step');
require('../flex-layout/components/video-player/js/component-video-player');
require('@demant/wdh-gdpr/lib/default.implementation.js');
    if (document.querySelector('.diary-management-online-booking')) {
      import(/* webpackChunkName: "chunk-num-1", webpackPrefetch: true */'../flex-layout/components/online-booking/OnlineBooking')
        .then(function (foo) { console.log('.diary-management-online-booking LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .diary-management-online-booking'); console.log(error); });
    }
    if (document.querySelector('.center-locator.flex-layout')) {
      import(/* webpackChunkName: "chunk-num-2", webpackPrefetch: true */'../flex-layout/components/center-locator/CenterLocator')
        .then(function (foo) { console.log('.center-locator.flex-layout LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .center-locator.flex-layout'); console.log(error); });
    }
    if (document.querySelector('.screening-test-spot.flex-layout')) {
      import(/* webpackChunkName: "chunk-num-3", webpackPrefetch: true */'../flex-layout/components/screening-test/js/component-screening-test')
        .then(function (foo) { console.log('.screening-test-spot.flex-layout LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .screening-test-spot.flex-layout'); console.log(error); });
    }
    if (document.querySelector('.hearing-test-invitation')) {
      import(/* webpackChunkName: "chunk-num-4", webpackPrefetch: true */'../flex-layout/components/hearing-test-invitation/js/component-hearing-test-invitation')
        .then(function (foo) { console.log('.hearing-test-invitation LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .hearing-test-invitation'); console.log(error); });
    }
    if (document.querySelector('.online-booking')) {
      import(/* webpackChunkName: "chunk-num-5", webpackPrefetch: true */'./feature/component-online-booking')
        .then(function (foo) { console.log('.online-booking LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .online-booking'); console.log(error); });
    }
    if (document.querySelector('.center-list-spot')) {
      import(/* webpackChunkName: "chunk-num-6", webpackPrefetch: true */'./feature/component-center-list-spot')
        .then(function (foo) { console.log('.center-list-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .center-list-spot'); console.log(error); });
    }
    if (document.querySelector('.centre-spot')) {
      import(/* webpackChunkName: "chunk-num-7", webpackPrefetch: true */'./feature/component-centre-spot')
        .then(function (foo) { console.log('.centre-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .centre-spot'); console.log(error); });
    }
    if (document.querySelector('.resource-center')) {
      import(/* webpackChunkName: "chunk-num-8", webpackPrefetch: true */'./feature/component-data-hierarchy-resource-center')
        .then(function (foo) { console.log('.resource-center LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .resource-center'); console.log(error); });
    }
    if (document.querySelector('.center-locator:not(.flex-layout)')) {
      import(/* webpackChunkName: "chunk-num-9", webpackPrefetch: true */'./feature/component-center-locator')
        .then(function (foo) { console.log('.center-locator:not(.flex-layout) LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .center-locator:not(.flex-layout)'); console.log(error); });
    }
    if (document.querySelector('.image-spot.flex-layout')) {
      import(/* webpackChunkName: "chunk-num-10", webpackPrefetch: true */'../flex-layout/components/image-spot/js/component-image-spot')
        .then(function (foo) { console.log('.image-spot.flex-layout LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .image-spot.flex-layout'); console.log(error); });
    }
    if (document.querySelector('.flex-spot-advanced')) {
      import(/* webpackChunkName: "chunk-num-11", webpackPrefetch: true */'../flex-layout/components/flexSpotAdvanced/index')
        .then(function (foo) { console.log('.flex-spot-advanced LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .flex-spot-advanced'); console.log(error); });
    }