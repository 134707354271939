require('@demant/legacy/custom-scroll');
require('../../../../wdh-foundation/dateformat/dateformat.js');
require('../../../../wdh-foundation/dgs/dgs-api.js');
require('../../../../wdh-foundation/dgs/dgs-events.js');
require('../../../../wdh-foundation/dgs-utils/dgs-utils.js');
require('@demant/legacy/googlemaps');
require('./markerclusterer');
require('iframe-resizer');
require('jquery-nice-select/js/jquery.nice-select.min.js');
require('jquery-ui-dist/jquery-ui.min.js');
require('lightgallery/src/js/lightgallery.js');
window.moment = require('moment');
require('gsap/TweenMax');
require('gsap/ScrollToPlugin');