function _typeof2(o) { "@babel/helpers - typeof"; return _typeof2 = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof2(o); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-names.js";
import "core-js/modules/es.object.get-prototype-of.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.set-prototype-of.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.flags.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(e) {
  return (_typeof = "function" == typeof Symbol && "symbol" == _typeof2(Symbol.iterator) ? function (e) {
    return _typeof2(e);
  } : function (e) {
    return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : _typeof2(e);
  })(e);
}
!function (e, t) {
  "object" == ("undefined" == typeof exports ? "undefined" : _typeof(exports)) && "undefined" != typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define(t) : (e = e || self).MarkerClustererPlus = t();
}(this, function () {
  "use strict";

  var e = "undefined" != typeof globalThis ? globalThis : "undefined" != typeof window ? window : "undefined" != typeof global ? global : "undefined" != typeof self ? self : {};
  function t(e, t) {
    return e(t = {
      exports: {}
    }, t.exports), t.exports;
  }
  var r = function r(e) {
      return e && e.Math == Math && e;
    },
    n = r("object" == ("undefined" == typeof globalThis ? "undefined" : _typeof(globalThis)) && globalThis) || r("object" == ("undefined" == typeof window ? "undefined" : _typeof(window)) && window) || r("object" == ("undefined" == typeof self ? "undefined" : _typeof(self)) && self) || r("object" == _typeof(e) && e) || Function("return this")(),
    i = function i(e) {
      try {
        return !!e();
      } catch (e) {
        return !0;
      }
    },
    o = !i(function () {
      return 7 != Object.defineProperty({}, 1, {
        get: function get() {
          return 7;
        }
      })[1];
    }),
    s = {}.propertyIsEnumerable,
    a = Object.getOwnPropertyDescriptor,
    u = {
      f: a && !s.call({
        1: 2
      }, 1) ? function (e) {
        var t = a(this, e);
        return !!t && t.enumerable;
      } : s
    },
    l = function l(e, t) {
      return {
        enumerable: !(1 & e),
        configurable: !(2 & e),
        writable: !(4 & e),
        value: t
      };
    },
    c = {}.toString,
    h = function h(e) {
      return c.call(e).slice(8, -1);
    },
    f = "".split,
    g = i(function () {
      return !Object("z").propertyIsEnumerable(0);
    }) ? function (e) {
      return "String" == h(e) ? f.call(e, "") : Object(e);
    } : Object,
    d = function d(e) {
      if (null == e) throw TypeError("Can't call method on " + e);
      return e;
    },
    p = function p(e) {
      return g(d(e));
    },
    v = function v(e) {
      return "object" == _typeof(e) ? null !== e : "function" == typeof e;
    },
    m = function m(e, t) {
      if (!v(e)) return e;
      var r, n;
      if (t && "function" == typeof (r = e.toString) && !v(n = r.call(e))) return n;
      if ("function" == typeof (r = e.valueOf) && !v(n = r.call(e))) return n;
      if (!t && "function" == typeof (r = e.toString) && !v(n = r.call(e))) return n;
      throw TypeError("Can't convert object to primitive value");
    },
    y = {}.hasOwnProperty,
    _ = function _(e, t) {
      return y.call(e, t);
    },
    k = n.document,
    b = v(k) && v(k.createElement),
    x = !o && !i(function () {
      return 7 != Object.defineProperty(("div", b ? k.createElement("div") : {}), "a", {
        get: function get() {
          return 7;
        }
      }).a;
    }),
    S = Object.getOwnPropertyDescriptor,
    M = {
      f: o ? S : function (e, t) {
        if (e = p(e), t = m(t, !0), x) try {
          return S(e, t);
        } catch (e) {}
        if (_(e, t)) return l(!u.f.call(e, t), e[t]);
      }
    },
    E = function E(e) {
      if (!v(e)) throw TypeError(String(e) + " is not an object");
      return e;
    },
    C = Object.defineProperty,
    I = {
      f: o ? C : function (e, t, r) {
        if (E(e), t = m(t, !0), E(r), x) try {
          return C(e, t, r);
        } catch (e) {}
        if ("get" in r || "set" in r) throw TypeError("Accessors not supported");
        return "value" in r && (e[t] = r.value), e;
      }
    },
    w = o ? function (e, t, r) {
      return I.f(e, t, l(1, r));
    } : function (e, t, r) {
      return e[t] = r, e;
    },
    j = function j(e, t) {
      try {
        w(n, e, t);
      } catch (r) {
        n[e] = t;
      }
      return t;
    },
    O = n["__core-js_shared__"] || j("__core-js_shared__", {}),
    T = Function.toString;
  "function" != typeof O.inspectSource && (O.inspectSource = function (e) {
    return T.call(e);
  });
  var A,
    P,
    L,
    z,
    R = O.inspectSource,
    B = n.WeakMap,
    Z = "function" == typeof B && /native code/.test(R(B)),
    D = t(function (e) {
      (e.exports = function (e, t) {
        return O[e] || (O[e] = void 0 !== t ? t : {});
      })("versions", []).push({
        version: "3.6.4",
        mode: "global",
        copyright: "© 2020 Denis Pushkarev (zloirock.ru)"
      });
    }),
    N = 0,
    H = Math.random(),
    $ = function $(e) {
      return "Symbol(" + String(void 0 === e ? "" : e) + ")_" + (++N + H).toString(36);
    },
    F = D("keys"),
    U = {},
    G = n.WeakMap;
  if (Z) {
    var V = new G(),
      W = V.get,
      X = V.has,
      q = V.set;
    A = function A(e, t) {
      return q.call(V, e, t), t;
    }, P = function P(e) {
      return W.call(V, e) || {};
    }, L = function L(e) {
      return X.call(V, e);
    };
  } else {
    var K = F[z = "state"] || (F[z] = $(z));
    U[K] = !0, A = function A(e, t) {
      return w(e, K, t), t;
    }, P = function P(e) {
      return _(e, K) ? e[K] : {};
    }, L = function L(e) {
      return _(e, K);
    };
  }
  var Y = {
      set: A,
      get: P,
      has: L,
      enforce: function enforce(e) {
        return L(e) ? P(e) : A(e, {});
      },
      getterFor: function getterFor(e) {
        return function (t) {
          var r;
          if (!v(t) || (r = P(t)).type !== e) throw TypeError("Incompatible receiver, " + e + " required");
          return r;
        };
      }
    },
    J = t(function (e) {
      var t = Y.get,
        r = Y.enforce,
        i = String(String).split("String");
      (e.exports = function (e, t, o, s) {
        var a = !!s && !!s.unsafe,
          u = !!s && !!s.enumerable,
          l = !!s && !!s.noTargetGet;
        "function" == typeof o && ("string" != typeof t || _(o, "name") || w(o, "name", t), r(o).source = i.join("string" == typeof t ? t : "")), e !== n ? (a ? !l && e[t] && (u = !0) : delete e[t], u ? e[t] = o : w(e, t, o)) : u ? e[t] = o : j(t, o);
      })(Function.prototype, "toString", function () {
        return "function" == typeof this && t(this).source || R(this);
      });
    }),
    Q = n,
    ee = function ee(e) {
      return "function" == typeof e ? e : void 0;
    },
    te = function te(e, t) {
      return arguments.length < 2 ? ee(Q[e]) || ee(n[e]) : Q[e] && Q[e][t] || n[e] && n[e][t];
    },
    re = Math.ceil,
    ne = Math.floor,
    ie = function ie(e) {
      return isNaN(e = +e) ? 0 : (e > 0 ? ne : re)(e);
    },
    oe = Math.min,
    se = function se(e) {
      return e > 0 ? oe(ie(e), 9007199254740991) : 0;
    },
    ae = Math.max,
    ue = Math.min,
    le = function le(e, t) {
      var r = ie(e);
      return r < 0 ? ae(r + t, 0) : ue(r, t);
    },
    ce = function ce(e) {
      return function (t, r, n) {
        var i,
          o = p(t),
          s = se(o.length),
          a = le(n, s);
        if (e && r != r) {
          for (; s > a;) if ((i = o[a++]) != i) return !0;
        } else for (; s > a; a++) if ((e || a in o) && o[a] === r) return e || a || 0;
        return !e && -1;
      };
    },
    he = {
      includes: ce(!0),
      indexOf: ce(!1)
    },
    fe = he.indexOf,
    ge = function ge(e, t) {
      var r,
        n = p(e),
        i = 0,
        o = [];
      for (r in n) !_(U, r) && _(n, r) && o.push(r);
      for (; t.length > i;) _(n, r = t[i++]) && (~fe(o, r) || o.push(r));
      return o;
    },
    de = ["constructor", "hasOwnProperty", "isPrototypeOf", "propertyIsEnumerable", "toLocaleString", "toString", "valueOf"],
    pe = de.concat("length", "prototype"),
    ve = {
      f: Object.getOwnPropertyNames || function (e) {
        return ge(e, pe);
      }
    },
    me = {
      f: Object.getOwnPropertySymbols
    },
    ye = te("Reflect", "ownKeys") || function (e) {
      var t = ve.f(E(e)),
        r = me.f;
      return r ? t.concat(r(e)) : t;
    },
    _e = function _e(e, t) {
      for (var r = ye(t), n = I.f, i = M.f, o = 0; o < r.length; o++) {
        var s = r[o];
        _(e, s) || n(e, s, i(t, s));
      }
    },
    ke = /#|\.prototype\./,
    be = function be(e, t) {
      var r = Se[xe(e)];
      return r == Ee || r != Me && ("function" == typeof t ? i(t) : !!t);
    },
    xe = be.normalize = function (e) {
      return String(e).replace(ke, ".").toLowerCase();
    },
    Se = be.data = {},
    Me = be.NATIVE = "N",
    Ee = be.POLYFILL = "P",
    Ce = be,
    Ie = M.f,
    we = function we(e, t) {
      var r,
        i,
        o,
        s,
        a,
        u = e.target,
        l = e.global,
        c = e.stat;
      if (r = l ? n : c ? n[u] || j(u, {}) : (n[u] || {}).prototype) for (i in t) {
        if (s = t[i], o = e.noTargetGet ? (a = Ie(r, i)) && a.value : r[i], !Ce(l ? i : u + (c ? "." : "#") + i, e.forced) && void 0 !== o) {
          if (_typeof(s) == _typeof(o)) continue;
          _e(s, o);
        }
        (e.sham || o && o.sham) && w(s, "sham", !0), J(r, i, s, e);
      }
    },
    je = function je(e, t) {
      var r = [][e];
      return !!r && i(function () {
        r.call(null, t || function () {
          throw 1;
        }, 1);
      });
    },
    Oe = Object.defineProperty,
    Te = {},
    Ae = function Ae(e) {
      throw e;
    },
    Pe = function Pe(e, t) {
      if (_(Te, e)) return Te[e];
      t || (t = {});
      var r = [][e],
        n = !!_(t, "ACCESSORS") && t.ACCESSORS,
        s = _(t, 0) ? t[0] : Ae,
        a = _(t, 1) ? t[1] : void 0;
      return Te[e] = !!r && !i(function () {
        if (n && !o) return !0;
        var e = {
          length: -1
        };
        n ? Oe(e, 1, {
          enumerable: !0,
          get: Ae
        }) : e[1] = 1, r.call(e, s, a);
      });
    },
    Le = he.indexOf,
    ze = [].indexOf,
    Re = !!ze && 1 / [1].indexOf(1, -0) < 0,
    Be = je("indexOf"),
    Ze = Pe("indexOf", {
      ACCESSORS: !0,
      1: 0
    });
  we({
    target: "Array",
    proto: !0,
    forced: Re || !Be || !Ze
  }, {
    indexOf: function indexOf(e) {
      return Re ? ze.apply(this, arguments) || 0 : Le(this, e, arguments.length > 1 ? arguments[1] : void 0);
    }
  });
  var De,
    Ne,
    He = Array.isArray || function (e) {
      return "Array" == h(e);
    },
    $e = function $e(e, t, r) {
      var n = m(t);
      n in e ? I.f(e, n, l(0, r)) : e[n] = r;
    },
    Fe = !!Object.getOwnPropertySymbols && !i(function () {
      return !String(Symbol());
    }),
    Ue = Fe && !Symbol.sham && "symbol" == _typeof(Symbol.iterator),
    Ge = D("wks"),
    Ve = n.Symbol,
    We = Ue ? Ve : Ve && Ve.withoutSetter || $,
    Xe = function Xe(e) {
      return _(Ge, e) || (Fe && _(Ve, e) ? Ge[e] = Ve[e] : Ge[e] = We("Symbol." + e)), Ge[e];
    },
    qe = te("navigator", "userAgent") || "",
    Ke = n.process,
    Ye = Ke && Ke.versions,
    Je = Ye && Ye.v8;
  Je ? Ne = (De = Je.split("."))[0] + De[1] : qe && (!(De = qe.match(/Edge\/(\d+)/)) || De[1] >= 74) && (De = qe.match(/Chrome\/(\d+)/)) && (Ne = De[1]);
  var Qe = Ne && +Ne,
    et = Xe("species"),
    tt = function tt(e) {
      return Qe >= 51 || !i(function () {
        var t = [];
        return (t.constructor = {})[et] = function () {
          return {
            foo: 1
          };
        }, 1 !== t[e](Boolean).foo;
      });
    },
    rt = tt("slice"),
    nt = Pe("slice", {
      ACCESSORS: !0,
      0: 0,
      1: 2
    }),
    it = Xe("species"),
    ot = [].slice,
    st = Math.max;
  we({
    target: "Array",
    proto: !0,
    forced: !rt || !nt
  }, {
    slice: function slice(e, t) {
      var r,
        n,
        i,
        o = p(this),
        s = se(o.length),
        a = le(e, s),
        u = le(void 0 === t ? s : t, s);
      if (He(o) && ("function" != typeof (r = o.constructor) || r !== Array && !He(r.prototype) ? v(r) && null === (r = r[it]) && (r = void 0) : r = void 0, r === Array || void 0 === r)) return ot.call(o, a, u);
      for (n = new (void 0 === r ? Array : r)(st(u - a, 0)), i = 0; a < u; a++, i++) a in o && $e(n, i, o[a]);
      return n.length = i, n;
    }
  });
  var at = function at(e) {
      return Object(d(e));
    },
    ut = Xe("species"),
    lt = function lt(e, t) {
      var r;
      return He(e) && ("function" != typeof (r = e.constructor) || r !== Array && !He(r.prototype) ? v(r) && null === (r = r[ut]) && (r = void 0) : r = void 0), new (void 0 === r ? Array : r)(0 === t ? 0 : t);
    },
    ct = tt("splice"),
    ht = Pe("splice", {
      ACCESSORS: !0,
      0: 0,
      1: 2
    }),
    ft = Math.max,
    gt = Math.min;
  we({
    target: "Array",
    proto: !0,
    forced: !ct || !ht
  }, {
    splice: function splice(e, t) {
      var r,
        n,
        i,
        o,
        s,
        a,
        u = at(this),
        l = se(u.length),
        c = le(e, l),
        h = arguments.length;
      if (0 === h ? r = n = 0 : 1 === h ? (r = 0, n = l - c) : (r = h - 2, n = gt(ft(ie(t), 0), l - c)), l + r - n > 9007199254740991) throw TypeError("Maximum allowed length exceeded");
      for (i = lt(u, n), o = 0; o < n; o++) (s = c + o) in u && $e(i, o, u[s]);
      if (i.length = n, r < n) {
        for (o = c; o < l - n; o++) a = o + r, (s = o + n) in u ? u[a] = u[s] : delete u[a];
        for (o = l; o > l - n + r; o--) delete u[o - 1];
      } else if (r > n) for (o = l - n; o > c; o--) a = o + r - 1, (s = o + n - 1) in u ? u[a] = u[s] : delete u[a];
      for (o = 0; o < r; o++) u[o + c] = arguments[o + 2];
      return u.length = l - n + r, i;
    }
  });
  var dt = Date.prototype,
    pt = dt.toString,
    vt = dt.getTime;
  new Date(NaN) + "" != "Invalid Date" && J(dt, "toString", function () {
    var e = vt.call(this);
    return e == e ? pt.call(this) : "Invalid Date";
  });
  var mt = Object.keys || function (e) {
      return ge(e, de);
    },
    yt = Object.assign,
    _t = Object.defineProperty,
    kt = !yt || i(function () {
      if (o && 1 !== yt({
        b: 1
      }, yt(_t({}, "a", {
        enumerable: !0,
        get: function get() {
          _t(this, "b", {
            value: 3,
            enumerable: !1
          });
        }
      }), {
        b: 2
      })).b) return !0;
      var e = {},
        t = {},
        r = Symbol();
      return e[r] = 7, "abcdefghijklmnopqrst".split("").forEach(function (e) {
        t[e] = e;
      }), 7 != yt({}, e)[r] || "abcdefghijklmnopqrst" != mt(yt({}, t)).join("");
    }) ? function (e, t) {
      for (var r = at(e), n = arguments.length, i = 1, s = me.f, a = u.f; n > i;) for (var l, c = g(arguments[i++]), h = s ? mt(c).concat(s(c)) : mt(c), f = h.length, d = 0; f > d;) l = h[d++], o && !a.call(c, l) || (r[l] = c[l]);
      return r;
    } : yt;
  we({
    target: "Object",
    stat: !0,
    forced: Object.assign !== kt
  }, {
    assign: kt
  });
  var bt = {};
  bt[Xe("toStringTag")] = "z";
  var xt = "[object z]" === String(bt),
    St = Xe("toStringTag"),
    Mt = "Arguments" == h(function () {
      return arguments;
    }()),
    Et = xt ? h : function (e) {
      var t, r, n;
      return void 0 === e ? "Undefined" : null === e ? "Null" : "string" == typeof (r = function (e, t) {
        try {
          return e[t];
        } catch (e) {}
      }(t = Object(e), St)) ? r : Mt ? h(t) : "Object" == (n = h(t)) && "function" == typeof t.callee ? "Arguments" : n;
    },
    Ct = xt ? {}.toString : function () {
      return "[object " + Et(this) + "]";
    };
  xt || J(Object.prototype, "toString", Ct, {
    unsafe: !0
  });
  var It = function It() {
      var e = E(this),
        t = "";
      return e.global && (t += "g"), e.ignoreCase && (t += "i"), e.multiline && (t += "m"), e.dotAll && (t += "s"), e.unicode && (t += "u"), e.sticky && (t += "y"), t;
    },
    wt = RegExp.prototype,
    jt = wt.toString,
    Ot = i(function () {
      return "/a/b" != jt.call({
        source: "a",
        flags: "b"
      });
    }),
    Tt = "toString" != jt.name;
  function At(e, t) {
    if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
  }
  function Pt(e, t) {
    for (var r = 0; r < t.length; r++) {
      var n = t[r];
      n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
    }
  }
  function Lt(e, t, r) {
    return t && Pt(e.prototype, t), r && Pt(e, r), e;
  }
  function zt(e, t) {
    if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function");
    e.prototype = Object.create(t && t.prototype, {
      constructor: {
        value: e,
        writable: !0,
        configurable: !0
      }
    }), t && function (e, t) {
      (Object.setPrototypeOf || function (e, t) {
        return e.__proto__ = t, e;
      })(e, t);
    }(e, t);
  }
  function Rt(e) {
    return (Rt = Object.setPrototypeOf ? Object.getPrototypeOf : function (e) {
      return e.__proto__ || Object.getPrototypeOf(e);
    })(e);
  }
  function Bt(e, t) {
    return !t || "object" != _typeof(t) && "function" != typeof t ? function (e) {
      if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return e;
    }(e) : t;
  }
  (Ot || Tt) && J(RegExp.prototype, "toString", function () {
    var e = E(this),
      t = String(e.source),
      r = e.flags;
    return "/" + t + "/" + String(void 0 === r && e instanceof RegExp && !("flags" in wt) ? It.call(e) : r);
  }, {
    unsafe: !0
  });
  var Zt = Xe("isConcatSpreadable"),
    Dt = Qe >= 51 || !i(function () {
      var e = [];
      return e[Zt] = !1, e.concat()[0] !== e;
    }),
    Nt = tt("concat"),
    Ht = function Ht(e) {
      if (!v(e)) return !1;
      var t = e[Zt];
      return void 0 !== t ? !!t : He(e);
    };
  we({
    target: "Array",
    proto: !0,
    forced: !Dt || !Nt
  }, {
    concat: function concat(e) {
      var t,
        r,
        n,
        i,
        o,
        s = at(this),
        a = lt(s, 0),
        u = 0;
      for (t = -1, n = arguments.length; t < n; t++) if (o = -1 === t ? s : arguments[t], Ht(o)) {
        if (u + (i = se(o.length)) > 9007199254740991) throw TypeError("Maximum allowed index exceeded");
        for (r = 0; r < i; r++, u++) r in o && $e(a, u, o[r]);
      } else {
        if (u >= 9007199254740991) throw TypeError("Maximum allowed index exceeded");
        $e(a, u++, o);
      }
      return a.length = u, a;
    }
  });
  var $t = [].join,
    Ft = g != Object,
    Ut = je("join", ",");
  we({
    target: "Array",
    proto: !0,
    forced: Ft || !Ut
  }, {
    join: function join(e) {
      return $t.call(p(this), void 0 === e ? "," : e);
    }
  });
  var Gt = function Gt(e) {
      if ("function" != typeof e) throw TypeError(String(e) + " is not a function");
      return e;
    },
    Vt = function Vt(e) {
      return function (t, r, n, i) {
        Gt(r);
        var o = at(t),
          s = g(o),
          a = se(o.length),
          u = e ? a - 1 : 0,
          l = e ? -1 : 1;
        if (n < 2) for (;;) {
          if (u in s) {
            i = s[u], u += l;
            break;
          }
          if (u += l, e ? u < 0 : a <= u) throw TypeError("Reduce of empty array with no initial value");
        }
        for (; e ? u >= 0 : a > u; u += l) u in s && (i = r(i, s[u], u, o));
        return i;
      };
    },
    Wt = [Vt(!1), Vt(!0)][0],
    Xt = je("reduce"),
    qt = Pe("reduce", {
      1: 0
    });
  we({
    target: "Array",
    proto: !0,
    forced: !Xt || !qt
  }, {
    reduce: function reduce(e) {
      return Wt(this, e, arguments.length, arguments.length > 1 ? arguments[1] : void 0);
    }
  }), we({
    target: "Object",
    stat: !0,
    forced: i(function () {
      mt(1);
    })
  }, {
    keys: function keys(e) {
      return mt(at(e));
    }
  });
  var Kt = "\t\n\x0B\f\r \xA0\u1680\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u3000\u2028\u2029\uFEFF",
    Yt = "[" + Kt + "]",
    Jt = RegExp("^" + Yt + Yt + "*"),
    Qt = RegExp(Yt + Yt + "*$"),
    er = function er(e) {
      return function (t) {
        var r = String(d(t));
        return 1 & e && (r = r.replace(Jt, "")), 2 & e && (r = r.replace(Qt, "")), r;
      };
    },
    tr = (er(1), er(2), er(3)),
    rr = n.parseInt,
    nr = /^[+-]?0[Xx]/,
    ir = 8 !== rr(Kt + "08") || 22 !== rr(Kt + "0x16") ? function (e, t) {
      var r = tr(String(e));
      return rr(r, t >>> 0 || (nr.test(r) ? 16 : 10));
    } : rr;
  function or(e, t) {
    return RegExp(e, t);
  }
  we({
    global: !0,
    forced: parseInt != ir
  }, {
    parseInt: ir
  });
  var sr,
    ar,
    ur = {
      UNSUPPORTED_Y: i(function () {
        var e = or("a", "y");
        return e.lastIndex = 2, null != e.exec("abcd");
      }),
      BROKEN_CARET: i(function () {
        var e = or("^r", "gy");
        return e.lastIndex = 2, null != e.exec("str");
      })
    },
    lr = RegExp.prototype.exec,
    cr = String.prototype.replace,
    hr = lr,
    fr = (sr = /a/, ar = /b*/g, lr.call(sr, "a"), lr.call(ar, "a"), 0 !== sr.lastIndex || 0 !== ar.lastIndex),
    gr = ur.UNSUPPORTED_Y || ur.BROKEN_CARET,
    dr = void 0 !== /()??/.exec("")[1];
  (fr || dr || gr) && (hr = function hr(e) {
    var t,
      r,
      n,
      i,
      o = this,
      s = gr && o.sticky,
      a = It.call(o),
      u = o.source,
      l = 0,
      c = e;
    return s && (-1 === (a = a.replace("y", "")).indexOf("g") && (a += "g"), c = String(e).slice(o.lastIndex), o.lastIndex > 0 && (!o.multiline || o.multiline && "\n" !== e[o.lastIndex - 1]) && (u = "(?: " + u + ")", c = " " + c, l++), r = new RegExp("^(?:" + u + ")", a)), dr && (r = new RegExp("^" + u + "$(?!\\s)", a)), fr && (t = o.lastIndex), n = lr.call(s ? r : o, c), s ? n ? (n.input = n.input.slice(l), n[0] = n[0].slice(l), n.index = o.lastIndex, o.lastIndex += n[0].length) : o.lastIndex = 0 : fr && n && (o.lastIndex = o.global ? n.index + n[0].length : t), dr && n && n.length > 1 && cr.call(n[0], r, function () {
      for (i = 1; i < arguments.length - 2; i++) void 0 === arguments[i] && (n[i] = void 0);
    }), n;
  });
  var pr = hr;
  we({
    target: "RegExp",
    proto: !0,
    forced: /./.exec !== pr
  }, {
    exec: pr
  });
  var vr = Xe("species"),
    mr = !i(function () {
      var e = /./;
      return e.exec = function () {
        var e = [];
        return e.groups = {
          a: "7"
        }, e;
      }, "7" !== "".replace(e, "$<a>");
    }),
    yr = "$0" === "a".replace(/./, "$0"),
    _r = Xe("replace"),
    kr = !!/./[_r] && "" === /./[_r]("a", "$0"),
    br = !i(function () {
      var e = /(?:)/,
        t = e.exec;
      e.exec = function () {
        return t.apply(this, arguments);
      };
      var r = "ab".split(e);
      return 2 !== r.length || "a" !== r[0] || "b" !== r[1];
    }),
    xr = function xr(e, t, r, n) {
      var o = Xe(e),
        s = !i(function () {
          var t = {};
          return t[o] = function () {
            return 7;
          }, 7 != ""[e](t);
        }),
        a = s && !i(function () {
          var t = !1,
            r = /a/;
          return "split" === e && ((r = {}).constructor = {}, r.constructor[vr] = function () {
            return r;
          }, r.flags = "", r[o] = /./[o]), r.exec = function () {
            return t = !0, null;
          }, r[o](""), !t;
        });
      if (!s || !a || "replace" === e && (!mr || !yr || kr) || "split" === e && !br) {
        var u = /./[o],
          l = r(o, ""[e], function (e, t, r, n, i) {
            return t.exec === pr ? s && !i ? {
              done: !0,
              value: u.call(t, r, n)
            } : {
              done: !0,
              value: e.call(r, t, n)
            } : {
              done: !1
            };
          }, {
            REPLACE_KEEPS_$0: yr,
            REGEXP_REPLACE_SUBSTITUTES_UNDEFINED_CAPTURE: kr
          }),
          c = l[0],
          h = l[1];
        J(String.prototype, e, c), J(RegExp.prototype, o, 2 == t ? function (e, t) {
          return h.call(e, this, t);
        } : function (e) {
          return h.call(e, this);
        });
      }
      n && w(RegExp.prototype[o], "sham", !0);
    },
    Sr = function Sr(e) {
      return function (t, r) {
        var n,
          i,
          o = String(d(t)),
          s = ie(r),
          a = o.length;
        return s < 0 || s >= a ? e ? "" : void 0 : (n = o.charCodeAt(s)) < 55296 || n > 56319 || s + 1 === a || (i = o.charCodeAt(s + 1)) < 56320 || i > 57343 ? e ? o.charAt(s) : n : e ? o.slice(s, s + 2) : i - 56320 + (n - 55296 << 10) + 65536;
      };
    },
    Mr = (Sr(!1), Sr(!0)),
    Er = function Er(e, t, r) {
      return t + (r ? Mr(e, t).length : 1);
    },
    Cr = function Cr(e, t) {
      var r = e.exec;
      if ("function" == typeof r) {
        var n = r.call(e, t);
        if ("object" != _typeof(n)) throw TypeError("RegExp exec method returned something other than an Object or null");
        return n;
      }
      if ("RegExp" !== h(e)) throw TypeError("RegExp#exec called on incompatible receiver");
      return pr.call(e, t);
    },
    Ir = Math.max,
    wr = Math.min,
    jr = Math.floor,
    Or = /\$([$&'`]|\d\d?|<[^>]*>)/g,
    Tr = /\$([$&'`]|\d\d?)/g;
  xr("replace", 2, function (e, t, r, n) {
    var i = n.REGEXP_REPLACE_SUBSTITUTES_UNDEFINED_CAPTURE,
      o = n.REPLACE_KEEPS_$0,
      s = i ? "$" : "$0";
    return [function (r, n) {
      var i = d(this),
        o = null == r ? void 0 : r[e];
      return void 0 !== o ? o.call(r, i, n) : t.call(String(i), r, n);
    }, function (e, n) {
      if (!i && o || "string" == typeof n && -1 === n.indexOf(s)) {
        var u = r(t, e, this, n);
        if (u.done) return u.value;
      }
      var l = E(e),
        c = String(this),
        h = "function" == typeof n;
      h || (n = String(n));
      var f = l.global;
      if (f) {
        var g = l.unicode;
        l.lastIndex = 0;
      }
      for (var d = [];;) {
        var p = Cr(l, c);
        if (null === p) break;
        if (d.push(p), !f) break;
        "" === String(p[0]) && (l.lastIndex = Er(c, se(l.lastIndex), g));
      }
      for (var v, m = "", y = 0, _ = 0; _ < d.length; _++) {
        p = d[_];
        for (var k = String(p[0]), b = Ir(wr(ie(p.index), c.length), 0), x = [], S = 1; S < p.length; S++) x.push(void 0 === (v = p[S]) ? v : String(v));
        var M = p.groups;
        if (h) {
          var C = [k].concat(x, b, c);
          void 0 !== M && C.push(M);
          var I = String(n.apply(void 0, C));
        } else I = a(k, c, b, x, M, n);
        b >= y && (m += c.slice(y, b) + I, y = b + k.length);
      }
      return m + c.slice(y);
    }];
    function a(e, r, n, i, o, s) {
      var a = n + e.length,
        u = i.length,
        l = Tr;
      return void 0 !== o && (o = at(o), l = Or), t.call(s, l, function (t, s) {
        var l;
        switch (s.charAt(0)) {
          case "$":
            return "$";
          case "&":
            return e;
          case "`":
            return r.slice(0, n);
          case "'":
            return r.slice(a);
          case "<":
            l = o[s.slice(1, -1)];
            break;
          default:
            var c = +s;
            if (0 === c) return t;
            if (c > u) {
              var h = jr(c / 10);
              return 0 === h ? t : h <= u ? void 0 === i[h - 1] ? s.charAt(1) : i[h - 1] + s.charAt(1) : t;
            }
            l = i[c - 1];
        }
        return void 0 === l ? "" : l;
      });
    }
  });
  var Ar = Xe("match"),
    Pr = Xe("species"),
    Lr = [].push,
    zr = Math.min,
    Rr = !i(function () {
      return !RegExp(4294967295, "y");
    });
  xr("split", 2, function (e, t, r) {
    var n;
    return n = "c" == "abbc".split(/(b)*/)[1] || 4 != "test".split(/(?:)/, -1).length || 2 != "ab".split(/(?:ab)*/).length || 4 != ".".split(/(.?)(.?)/).length || ".".split(/()()/).length > 1 || "".split(/.?/).length ? function (e, r) {
      var n,
        i,
        o = String(d(this)),
        s = void 0 === r ? 4294967295 : r >>> 0;
      if (0 === s) return [];
      if (void 0 === e) return [o];
      if (!v(n = e) || (void 0 !== (i = n[Ar]) ? !i : "RegExp" != h(n))) return t.call(o, e, s);
      for (var a, u, l, c = [], f = (e.ignoreCase ? "i" : "") + (e.multiline ? "m" : "") + (e.unicode ? "u" : "") + (e.sticky ? "y" : ""), g = 0, p = new RegExp(e.source, f + "g"); (a = pr.call(p, o)) && !((u = p.lastIndex) > g && (c.push(o.slice(g, a.index)), a.length > 1 && a.index < o.length && Lr.apply(c, a.slice(1)), l = a[0].length, g = u, c.length >= s));) p.lastIndex === a.index && p.lastIndex++;
      return g === o.length ? !l && p.test("") || c.push("") : c.push(o.slice(g)), c.length > s ? c.slice(0, s) : c;
    } : "0".split(void 0, 0).length ? function (e, r) {
      return void 0 === e && 0 === r ? [] : t.call(this, e, r);
    } : t, [function (t, r) {
      var i = d(this),
        o = null == t ? void 0 : t[e];
      return void 0 !== o ? o.call(t, i, r) : n.call(String(i), t, r);
    }, function (e, i) {
      var o = r(n, e, this, i, n !== t);
      if (o.done) return o.value;
      var s = E(e),
        a = String(this),
        u = function (e, t) {
          var r,
            n = E(s).constructor;
          return void 0 === n || null == (r = E(n)[Pr]) ? t : Gt(r);
        }(0, RegExp),
        l = s.unicode,
        c = (s.ignoreCase ? "i" : "") + (s.multiline ? "m" : "") + (s.unicode ? "u" : "") + (Rr ? "y" : "g"),
        h = new u(Rr ? s : "^(?:" + s.source + ")", c),
        f = void 0 === i ? 4294967295 : i >>> 0;
      if (0 === f) return [];
      if (0 === a.length) return null === Cr(h, a) ? [a] : [];
      for (var g = 0, d = 0, p = []; d < a.length;) {
        h.lastIndex = Rr ? d : 0;
        var v,
          m = Cr(h, Rr ? a : a.slice(d));
        if (null === m || (v = zr(se(h.lastIndex + (Rr ? 0 : d)), a.length)) === g) d = Er(a, d, l);else {
          if (p.push(a.slice(g, d)), p.length === f) return p;
          for (var y = 1; y <= m.length - 1; y++) if (p.push(m[y]), p.length === f) return p;
          d = g = v;
        }
      }
      return p.push(a.slice(g)), p;
    }];
  }, !Rr);
  var Br = function e() {
    At(this, e), function (e, t) {
      for (var r in t.prototype) e.prototype[r] = t.prototype[r];
    }(e, google.maps.OverlayView);
  };
  function Zr(e) {
    return Object.keys(e).reduce(function (t, r) {
      return e[r] && t.push(r + ":" + e[r]), t;
    }, []).join(";");
  }
  function Dr(e) {
    return e ? e + "px" : void 0;
  }
  var Nr = function (e) {
      function t(e, r) {
        var n;
        return At(this, t), (n = Bt(this, Rt(t).call(this))).cluster_ = e, n.styles_ = r, n.center_ = null, n.div_ = null, n.sums_ = null, n.visible_ = !1, n.style = null, n.setMap(e.getMap()), n;
      }
      return zt(t, Br), Lt(t, [{
        key: "onAdd",
        value: function value() {
          var e,
            t,
            r = this,
            n = this.cluster_.getMarkerClusterer(),
            i = function (e, t) {
              return function (e) {
                if (Array.isArray(e)) return e;
              }(e) || function (e, t) {
                if (Symbol.iterator in Object(e) || "[object Arguments]" === Object.prototype.toString.call(e)) {
                  var r = [],
                    n = !0,
                    i = !1,
                    o = void 0;
                  try {
                    for (var s, a = e[Symbol.iterator](); !(n = (s = a.next()).done) && (r.push(s.value), !t || r.length !== t); n = !0);
                  } catch (e) {
                    i = !0, o = e;
                  } finally {
                    try {
                      n || null == a.return || a.return();
                    } finally {
                      if (i) throw o;
                    }
                  }
                  return r;
                }
              }(e, t) || function () {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
              }();
            }(google.maps.version.split("."), 2),
            o = i[0],
            s = i[1],
            a = 100 * parseInt(o, 10) + parseInt(s, 10);
          this.div_ = document.createElement("div"), this.visible_ && this.show(), this.getPanes().overlayMouseTarget.appendChild(this.div_), this.boundsChangedListener_ = google.maps.event.addListener(this.getMap(), "bounds_changed", function () {
            t = e;
          }), google.maps.event.addDomListener(this.div_, "mousedown", function () {
            e = !0, t = !1;
          }), a >= 332 && google.maps.event.addDomListener(this.div_, "touchstart", function (e) {
            e.stopPropagation();
          }), google.maps.event.addDomListener(this.div_, "click", function (i) {
            if (e = !1, !t) {
              if (google.maps.event.trigger(n, "click", r.cluster_), google.maps.event.trigger(n, "clusterclick", r.cluster_), n.getZoomOnClick()) {
                var o = n.getMaxZoom(),
                  s = r.cluster_.getBounds();
                n.getMap().fitBounds(s), setTimeout(function () {
                  n.getMap().fitBounds(s), null !== o && n.getMap().getZoom() > o && n.getMap().setZoom(o + 1);
                }, 100);
              }
              i.cancelBubble = !0, i.stopPropagation && i.stopPropagation();
            }
          }), google.maps.event.addDomListener(this.div_, "mouseover", function () {
            google.maps.event.trigger(n, "mouseover", r.cluster_);
          }), google.maps.event.addDomListener(this.div_, "mouseout", function () {
            google.maps.event.trigger(n, "mouseout", r.cluster_);
          });
        }
      }, {
        key: "onRemove",
        value: function value() {
          this.div_ && this.div_.parentNode && (this.hide(), google.maps.event.removeListener(this.boundsChangedListener_), google.maps.event.clearInstanceListeners(this.div_), this.div_.parentNode.removeChild(this.div_), this.div_ = null);
        }
      }, {
        key: "draw",
        value: function value() {
          if (this.visible_) {
            var e = this.getPosFromLatLng_(this.center_);
            this.div_.style.top = e.y + "px", this.div_.style.left = e.x + "px";
          }
        }
      }, {
        key: "hide",
        value: function value() {
          this.div_ && (this.div_.style.display = "none"), this.visible_ = !1;
        }
      }, {
        key: "show",
        value: function value() {
          this.div_ && (this.div_.className = this.className_, this.div_.style.cssText = this.createCss_(this.getPosFromLatLng_(this.center_)), this.div_.innerHTML = (this.style.url ? this.getImageElementHtml() : "") + this.getLabelDivHtml(), void 0 === this.sums_.title || "" === this.sums_.title ? this.div_.title = this.cluster_.getMarkerClusterer().getTitle() : this.div_.title = this.sums_.title, this.div_.style.display = ""), this.visible_ = !0;
        }
      }, {
        key: "getLabelDivHtml",
        value: function value() {
          var e = this.cluster_.getMarkerClusterer().ariaLabelFn(this.sums_.text),
            t = {
              position: "absolute",
              top: Dr(this.anchorText_[0]),
              left: Dr(this.anchorText_[1]),
              color: this.style.textColor,
              "font-size": Dr(this.style.textSize),
              "font-family": this.style.fontFamily,
              "font-weight": this.style.fontWeight,
              "font-style": this.style.fontStyle,
              "text-decoration": this.style.textDecoration,
              "text-align": "center",
              width: Dr(this.style.width),
              "line-height": Dr(this.style.textLineHeight)
            };
          return '\n<div aria-label="'.concat(e, '" style="').concat(Zr(t), '" tabindex="0">\n  <span aria-hidden="true">').concat(this.sums_.text, "</span>\n</div>\n");
        }
      }, {
        key: "getImageElementHtml",
        value: function value() {
          var e = (this.style.backgroundPosition || "0 0").split(" "),
            t = parseInt(e[0].replace(/^\s+|\s+$/g, ""), 10),
            r = parseInt(e[1].replace(/^\s+|\s+$/g, ""), 10),
            n = {};
          if (this.cluster_.getMarkerClusterer().getEnableRetinaIcons()) n = {
            width: Dr(this.style.width),
            height: Dr(this.style.height)
          };else {
            var i = -1 * r,
              o = -1 * t + this.style.width,
              s = -1 * r + this.style.height,
              a = -1 * t;
            n = {
              clip: "rect(".concat(i, "px, ").concat(o, "px, ").concat(s, "px, ").concat(a, "px)")
            };
          }
          var u = Zr(Object.assign({
            position: "absolute",
            top: Dr(r),
            left: Dr(t)
          }, n));
          return '<img alt="'.concat(this.sums_.text, '" aria-hidden="true" src="').concat(this.style.url, '" style="').concat(u, '"/>');
        }
      }, {
        key: "useStyle",
        value: function value(e) {
          this.sums_ = e;
          var t = Math.max(0, e.index - 1);
          t = Math.min(this.styles_.length - 1, t), this.style = this.styles_[t], this.anchorText_ = this.style.anchorText || [0, 0], this.anchorIcon_ = this.style.anchorIcon || [Math.floor(this.style.height / 2), Math.floor(this.style.width / 2)], this.className_ = this.cluster_.getMarkerClusterer().getClusterClass() + " " + (this.style.className || "cluster-" + t);
        }
      }, {
        key: "setCenter",
        value: function value(e) {
          this.center_ = e;
        }
      }, {
        key: "createCss_",
        value: function value(e) {
          return Zr({
            "z-index": "".concat(this.cluster_.getMarkerClusterer().getZIndex()),
            top: Dr(e.y),
            left: Dr(e.x),
            width: Dr(this.style.width),
            height: Dr(this.style.height),
            cursor: "pointer",
            position: "absolute",
            "-webkit-user-select": "none",
            "-khtml-user-select": "none",
            "-moz-user-select": "none",
            "-o-user-select": "none",
            "user-select": "none"
          });
        }
      }, {
        key: "getPosFromLatLng_",
        value: function value(e) {
          var t = this.getProjection().fromLatLngToDivPixel(e);
          return t.x = Math.floor(t.x - this.anchorIcon_[1]), t.y = Math.floor(t.y - this.anchorIcon_[0]), t;
        }
      }]), t;
    }(),
    Hr = function () {
      function e(t) {
        At(this, e), this.markerClusterer_ = t, this.map_ = this.markerClusterer_.getMap(), this.minClusterSize_ = this.markerClusterer_.getMinimumClusterSize(), this.averageCenter_ = this.markerClusterer_.getAverageCenter(), this.markers_ = [], this.center_ = null, this.bounds_ = null, this.clusterIcon_ = new Nr(this, this.markerClusterer_.getStyles());
      }
      return Lt(e, [{
        key: "getSize",
        value: function value() {
          return this.markers_.length;
        }
      }, {
        key: "getMarkers",
        value: function value() {
          return this.markers_;
        }
      }, {
        key: "getCenter",
        value: function value() {
          return this.center_;
        }
      }, {
        key: "getMap",
        value: function value() {
          return this.map_;
        }
      }, {
        key: "getMarkerClusterer",
        value: function value() {
          return this.markerClusterer_;
        }
      }, {
        key: "getBounds",
        value: function value() {
          for (var e = new google.maps.LatLngBounds(this.center_, this.center_), t = this.getMarkers(), r = 0; r < t.length; r++) e.extend(t[r].getPosition());
          return e;
        }
      }, {
        key: "remove",
        value: function value() {
          this.clusterIcon_.setMap(null), this.markers_ = [], delete this.markers_;
        }
      }, {
        key: "addMarker",
        value: function value(e) {
          if (this.isMarkerAlreadyAdded_(e)) return !1;
          if (this.center_) {
            if (this.averageCenter_) {
              var t = this.markers_.length + 1,
                r = (this.center_.lat() * (t - 1) + e.getPosition().lat()) / t,
                n = (this.center_.lng() * (t - 1) + e.getPosition().lng()) / t;
              this.center_ = new google.maps.LatLng(r, n), this.calculateBounds_();
            }
          } else this.center_ = e.getPosition(), this.calculateBounds_();
          e.isAdded = !0, this.markers_.push(e);
          var i = this.markers_.length,
            o = this.markerClusterer_.getMaxZoom();
          if (null !== o && this.map_.getZoom() > o) e.getMap() !== this.map_ && e.setMap(this.map_);else if (i < this.minClusterSize_) e.getMap() !== this.map_ && e.setMap(this.map_);else if (i === this.minClusterSize_) for (var s = 0; s < i; s++) this.markers_[s].setMap(null);else e.setMap(null);
          return !0;
        }
      }, {
        key: "isMarkerInClusterBounds",
        value: function value(e) {
          return this.bounds_.contains(e.getPosition());
        }
      }, {
        key: "calculateBounds_",
        value: function value() {
          var e = new google.maps.LatLngBounds(this.center_, this.center_);
          this.bounds_ = this.markerClusterer_.getExtendedBounds(e);
        }
      }, {
        key: "updateIcon",
        value: function value() {
          var e = this.markers_.length,
            t = this.markerClusterer_.getMaxZoom();
          if (null !== t && this.map_.getZoom() > t) this.clusterIcon_.hide();else if (e < this.minClusterSize_) this.clusterIcon_.hide();else {
            var r = this.markerClusterer_.getStyles().length,
              n = this.markerClusterer_.getCalculator()(this.markers_, r);
            this.clusterIcon_.setCenter(this.center_), this.clusterIcon_.useStyle(n), this.clusterIcon_.show();
          }
        }
      }, {
        key: "isMarkerAlreadyAdded_",
        value: function value(e) {
          if (this.markers_.indexOf) return -1 !== this.markers_.indexOf(e);
          for (var t = 0; t < this.markers_.length; t++) if (e === this.markers_[t]) return !0;
          return !1;
        }
      }]), e;
    }(),
    $r = function $r(e, t, r) {
      return void 0 !== e[t] ? e[t] : r;
    },
    Fr = function (e) {
      function t(e) {
        var r,
          n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : [],
          i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
        return At(this, t), (r = Bt(this, Rt(t).call(this))).options = i, r.markers_ = [], r.clusters_ = [], r.listeners_ = [], r.activeMap_ = null, r.ready_ = !1, r.ariaLabelFn = r.options.ariaLabelFn || function () {
          return "";
        }, r.zIndex_ = r.options.zIndex || google.maps.Marker.MAX_ZINDEX + 1, r.gridSize_ = r.options.gridSize || 60, r.minClusterSize_ = r.options.minimumClusterSize || 2, r.maxZoom_ = r.options.maxZoom || null, r.styles_ = r.options.styles || [], r.title_ = r.options.title || "", r.zoomOnClick_ = $r(r.options, "zoomOnClick", !0), r.averageCenter_ = $r(r.options, "averageCenter", !1), r.ignoreHidden_ = $r(r.options, "ignoreHidden", !1), r.enableRetinaIcons_ = $r(r.options, "enableRetinaIcons", !1), r.imagePath_ = r.options.imagePath || t.IMAGE_PATH, r.imageExtension_ = r.options.imageExtension || t.IMAGE_EXTENSION, r.imageSizes_ = r.options.imageSizes || t.IMAGE_SIZES, r.calculator_ = r.options.calculator || t.CALCULATOR, r.batchSize_ = r.options.batchSize || t.BATCH_SIZE, r.batchSizeIE_ = r.options.batchSizeIE || t.BATCH_SIZE_IE, r.clusterClass_ = r.options.clusterClass || "cluster", -1 !== navigator.userAgent.toLowerCase().indexOf("msie") && (r.batchSize_ = r.batchSizeIE_), r.setupStyles_(), r.addMarkers(n, !0), r.setMap(e), r;
      }
      return zt(t, Br), Lt(t, [{
        key: "onAdd",
        value: function value() {
          var e = this;
          this.activeMap_ = this.getMap(), this.ready_ = !0, this.repaint(), this.prevZoom_ = this.getMap().getZoom(), this.listeners_ = [google.maps.event.addListener(this.getMap(), "zoom_changed", function () {
            var t = e.getMap(),
              r = t.minZoom || 0,
              n = Math.min(t.maxZoom || 100, t.mapTypes[t.getMapTypeId()].maxZoom),
              i = Math.min(Math.max(e.getMap().getZoom(), r), n);
            e.prevZoom_ != i && (e.prevZoom_ = i, e.resetViewport_(!1));
          }), google.maps.event.addListener(this.getMap(), "idle", function () {
            e.redraw_();
          })];
        }
      }, {
        key: "onRemove",
        value: function value() {
          for (var e = 0; e < this.markers_.length; e++) this.markers_[e].getMap() !== this.activeMap_ && this.markers_[e].setMap(this.activeMap_);
          for (var t = 0; t < this.clusters_.length; t++) this.clusters_[t].remove();
          this.clusters_ = [];
          for (var r = 0; r < this.listeners_.length; r++) google.maps.event.removeListener(this.listeners_[r]);
          this.listeners_ = [], this.activeMap_ = null, this.ready_ = !1;
        }
      }, {
        key: "draw",
        value: function value() {}
      }, {
        key: "setupStyles_",
        value: function value() {
          if (!(this.styles_.length > 0)) for (var e = 0; e < this.imageSizes_.length; e++) {
            var r = this.imageSizes_[e];
            this.styles_.push(t.withDefaultStyle({
              url: this.imagePath_ + (e + 1) + "." + this.imageExtension_,
              height: r,
              width: r
            }));
          }
        }
      }, {
        key: "fitMapToMarkers",
        value: function value() {
          for (var e = this.getMarkers(), t = new google.maps.LatLngBounds(), r = 0; r < e.length; r++) !e[r].getVisible() && this.getIgnoreHidden() || t.extend(e[r].getPosition());
          this.getMap().fitBounds(t);
        }
      }, {
        key: "getGridSize",
        value: function value() {
          return this.gridSize_;
        }
      }, {
        key: "setGridSize",
        value: function value(e) {
          this.gridSize_ = e;
        }
      }, {
        key: "getMinimumClusterSize",
        value: function value() {
          return this.minClusterSize_;
        }
      }, {
        key: "setMinimumClusterSize",
        value: function value(e) {
          this.minClusterSize_ = e;
        }
      }, {
        key: "getMaxZoom",
        value: function value() {
          return this.maxZoom_;
        }
      }, {
        key: "setMaxZoom",
        value: function value(e) {
          this.maxZoom_ = e;
        }
      }, {
        key: "getZIndex",
        value: function value() {
          return this.zIndex_;
        }
      }, {
        key: "setZIndex",
        value: function value(e) {
          this.zIndex_ = e;
        }
      }, {
        key: "getStyles",
        value: function value() {
          return this.styles_;
        }
      }, {
        key: "setStyles",
        value: function value(e) {
          this.styles_ = e;
        }
      }, {
        key: "getTitle",
        value: function value() {
          return this.title_;
        }
      }, {
        key: "setTitle",
        value: function value(e) {
          this.title_ = e;
        }
      }, {
        key: "getZoomOnClick",
        value: function value() {
          return this.zoomOnClick_;
        }
      }, {
        key: "setZoomOnClick",
        value: function value(e) {
          this.zoomOnClick_ = e;
        }
      }, {
        key: "getAverageCenter",
        value: function value() {
          return this.averageCenter_;
        }
      }, {
        key: "setAverageCenter",
        value: function value(e) {
          this.averageCenter_ = e;
        }
      }, {
        key: "getIgnoreHidden",
        value: function value() {
          return this.ignoreHidden_;
        }
      }, {
        key: "setIgnoreHidden",
        value: function value(e) {
          this.ignoreHidden_ = e;
        }
      }, {
        key: "getEnableRetinaIcons",
        value: function value() {
          return this.enableRetinaIcons_;
        }
      }, {
        key: "setEnableRetinaIcons",
        value: function value(e) {
          this.enableRetinaIcons_ = e;
        }
      }, {
        key: "getImageExtension",
        value: function value() {
          return this.imageExtension_;
        }
      }, {
        key: "setImageExtension",
        value: function value(e) {
          this.imageExtension_ = e;
        }
      }, {
        key: "getImagePath",
        value: function value() {
          return this.imagePath_;
        }
      }, {
        key: "setImagePath",
        value: function value(e) {
          this.imagePath_ = e;
        }
      }, {
        key: "getImageSizes",
        value: function value() {
          return this.imageSizes_;
        }
      }, {
        key: "setImageSizes",
        value: function value(e) {
          this.imageSizes_ = e;
        }
      }, {
        key: "getCalculator",
        value: function value() {
          return this.calculator_;
        }
      }, {
        key: "setCalculator",
        value: function value(e) {
          this.calculator_ = e;
        }
      }, {
        key: "getBatchSizeIE",
        value: function value() {
          return this.batchSizeIE_;
        }
      }, {
        key: "setBatchSizeIE",
        value: function value(e) {
          this.batchSizeIE_ = e;
        }
      }, {
        key: "getClusterClass",
        value: function value() {
          return this.clusterClass_;
        }
      }, {
        key: "setClusterClass",
        value: function value(e) {
          this.clusterClass_ = e;
        }
      }, {
        key: "getMarkers",
        value: function value() {
          return this.markers_;
        }
      }, {
        key: "getTotalMarkers",
        value: function value() {
          return this.markers_.length;
        }
      }, {
        key: "getClusters",
        value: function value() {
          return this.clusters_;
        }
      }, {
        key: "getTotalClusters",
        value: function value() {
          return this.clusters_.length;
        }
      }, {
        key: "addMarker",
        value: function value(e, t) {
          this.pushMarkerTo_(e), t || this.redraw_();
        }
      }, {
        key: "addMarkers",
        value: function value(e, t) {
          for (var r in e) Object.prototype.hasOwnProperty.call(e, r) && this.pushMarkerTo_(e[r]);
          t || this.redraw_();
        }
      }, {
        key: "pushMarkerTo_",
        value: function value(e) {
          var t = this;
          e.getDraggable() && google.maps.event.addListener(e, "dragend", function () {
            t.ready_ && (e.isAdded = !1, t.repaint());
          }), e.isAdded = !1, this.markers_.push(e);
        }
      }, {
        key: "removeMarker",
        value: function value(e, t) {
          var r = this.removeMarker_(e);
          return !t && r && this.repaint(), r;
        }
      }, {
        key: "removeMarkers",
        value: function value(e, t) {
          for (var r = !1, n = 0; n < e.length; n++) {
            var i = this.removeMarker_(e[n]);
            r = r || i;
          }
          return !t && r && this.repaint(), r;
        }
      }, {
        key: "removeMarker_",
        value: function value(e) {
          var t = -1;
          if (this.markers_.indexOf) t = this.markers_.indexOf(e);else for (var r = 0; r < this.markers_.length; r++) if (e === this.markers_[r]) {
            t = r;
            break;
          }
          return -1 !== t && (e.setMap(null), this.markers_.splice(t, 1), !0);
        }
      }, {
        key: "clearMarkers",
        value: function value() {
          this.resetViewport_(!0), this.markers_ = [];
        }
      }, {
        key: "repaint",
        value: function value() {
          var e = this.clusters_.slice();
          this.clusters_ = [], this.resetViewport_(!1), this.redraw_(), setTimeout(function () {
            for (var t = 0; t < e.length; t++) e[t].remove();
          }, 0);
        }
      }, {
        key: "getExtendedBounds",
        value: function value(e) {
          var t = this.getProjection(),
            r = new google.maps.LatLng(e.getNorthEast().lat(), e.getNorthEast().lng()),
            n = new google.maps.LatLng(e.getSouthWest().lat(), e.getSouthWest().lng()),
            i = t.fromLatLngToDivPixel(r);
          i.x += this.gridSize_, i.y -= this.gridSize_;
          var o = t.fromLatLngToDivPixel(n);
          o.x -= this.gridSize_, o.y += this.gridSize_;
          var s = t.fromDivPixelToLatLng(i),
            a = t.fromDivPixelToLatLng(o);
          return e.extend(s), e.extend(a), e;
        }
      }, {
        key: "redraw_",
        value: function value() {
          this.createClusters_(0);
        }
      }, {
        key: "resetViewport_",
        value: function value(e) {
          for (var t = 0; t < this.clusters_.length; t++) this.clusters_[t].remove();
          this.clusters_ = [];
          for (var r = 0; r < this.markers_.length; r++) {
            var n = this.markers_[r];
            n.isAdded = !1, e && n.setMap(null);
          }
        }
      }, {
        key: "distanceBetweenPoints_",
        value: function value(e, t) {
          var r = (t.lat() - e.lat()) * Math.PI / 180,
            n = (t.lng() - e.lng()) * Math.PI / 180,
            i = Math.sin(r / 2) * Math.sin(r / 2) + Math.cos(e.lat() * Math.PI / 180) * Math.cos(t.lat() * Math.PI / 180) * Math.sin(n / 2) * Math.sin(n / 2);
          return 2 * Math.atan2(Math.sqrt(i), Math.sqrt(1 - i)) * 6371;
        }
      }, {
        key: "isMarkerInBounds_",
        value: function value(e, t) {
          return t.contains(e.getPosition());
        }
      }, {
        key: "addToClosestCluster_",
        value: function value(e) {
          for (var t = 4e4, r = null, n = 0; n < this.clusters_.length; n++) {
            var i = this.clusters_[n],
              o = i.getCenter();
            if (o) {
              var s = this.distanceBetweenPoints_(o, e.getPosition());
              s < t && (t = s, r = i);
            }
          }
          if (r && r.isMarkerInClusterBounds(e)) r.addMarker(e);else {
            var a = new Hr(this);
            a.addMarker(e), this.clusters_.push(a);
          }
        }
      }, {
        key: "createClusters_",
        value: function value(e) {
          var t = this;
          if (this.ready_) {
            var r;
            0 === e && (google.maps.event.trigger(this, "clusteringbegin", this), void 0 !== this.timerRefStatic && (clearTimeout(this.timerRefStatic), delete this.timerRefStatic)), r = this.getMap().getZoom() > 3 ? new google.maps.LatLngBounds(this.getMap().getBounds().getSouthWest(), this.getMap().getBounds().getNorthEast()) : new google.maps.LatLngBounds(new google.maps.LatLng(85.02070771743472, -178.48388434375), new google.maps.LatLng(-85.08136444384544, 178.00048865625));
            for (var n = this.getExtendedBounds(r), i = Math.min(e + this.batchSize_, this.markers_.length), o = e; o < i; o++) {
              var s = this.markers_[o];
              !s.isAdded && this.isMarkerInBounds_(s, n) && (!this.ignoreHidden_ || this.ignoreHidden_ && s.getVisible()) && this.addToClosestCluster_(s);
            }
            if (i < this.markers_.length) this.timerRefStatic = window.setTimeout(function () {
              t.createClusters_(i);
            }, 0);else {
              delete this.timerRefStatic, google.maps.event.trigger(this, "clusteringend", this);
              for (var a = 0; a < this.clusters_.length; a++) this.clusters_[a].updateIcon();
            }
          }
        }
      }], [{
        key: "CALCULATOR",
        value: function value(e, t) {
          for (var r = 0, n = e.length, i = n; 0 !== i;) i = Math.floor(i / 10), r++;
          return r = Math.min(r, t), {
            text: n.toString(),
            index: r,
            title: ""
          };
        }
      }, {
        key: "withDefaultStyle",
        value: function value(e) {
          return Object.assign({
            textColor: "black",
            textSize: 11,
            textDecoration: "none",
            textLineHeight: e.height,
            fontWeight: "bold",
            fontStyle: "normal",
            fontFamily: "Arial,sans-serif",
            backgroundPosition: "0 0"
          }, e);
        }
      }]), t;
    }();
  return Fr.BATCH_SIZE = 2e3, Fr.BATCH_SIZE_IE = 500, Fr.IMAGE_PATH = "../images/m", Fr.IMAGE_EXTENSION = "png", Fr.IMAGE_SIZES = [53, 56, 66, 78, 90], Fr;
});