(function ($) {
  'use strict';

  window.DGS.OnLoad.getInstance().register(function () {
    var triggers = document.getElementsByClassName('modal-trigger');
    [].forEach.call(triggers, function (el) {
      $(el).on('click', function (e) {
        e.preventDefault();
        var modalUrl = el.href;
        $.ajax({
          url: modalUrl,
          type: "GET",
          dataType: "html",
          success: function success(pageContent) {
            var content = $(pageContent).find('.modal-content .component-content');
            if (content.length > 0) {
              prepareLightbox(content);
            } else {
              window.location = modalUrl;
            }
          },
          error: function error() {
            window.location = modalUrl;
          }
        });
      });
    });
    function prepareLightbox(content) {
      var lightboxContainer = $('.staff-lightbox-container');
      lightboxContainer.addClass('modal-container');
      lightboxContainer.empty();
      lightboxContainer.append('<div class="modal-container-content">' + '<a class="lightbox-close">X</a>' + '</div>');
      $('.modal-container-content').append(content);
      $('.modal-container .lightbox-close').on('click', function () {
        lightboxContainer.removeClass('modal-container');
        lightboxContainer.empty();
      });
    }
  });
})(jQuery);