import { onLoad } from '../../../../wdh-foundation/wdh';
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.component.site-notification-spot').each(function () {
      // function to see if cookie exists
      function getCookie(cName) {
        var cVal = document.cookie.match("(?:^|;) ?".concat(cName, "=([^;]*)(?:;|$)"));
        if (!cVal) {
          return '';
        }
        return cVal[1];
      }
      var spot = $(this);
      if ($('.component-content *', spot).length > 0) {
        // conditional display of notification spot based on notification cookie:
        var mycookie = getCookie('notification-cookie');
        if (!mycookie) {
          spot.css('display', 'block');
        } else {
          spot.css('display', 'none');
        }
        $('.component-content', spot).after().click(function () {
          spot.hide();
          // set cookie to register that notification spot has been shown
          window.DGS.Cookies.getInstance().createCookie('notification-cookie', 1, 1);
        });
      }
    });
  });
})(jQuery);