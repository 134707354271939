import './staff-lightbox';
(function ($) {
  window.DGS.CenterSpotConfig.mapBrowserWidth = true;
  window.DGS.OnLoad.getInstance().register(function () {
    $('.component.centre-spot').each(function () {
      $('.center-specific-information, .center-general-information', $(this)).wrapAll("<div class='center-info'></div>");
      $('.center-info', $(this)).wrap("<div class='center-info-outer'></div>");
      $('.center-info-outer', $(this)).insertBefore('.address-book');
      // hide the info box if no info content from Sitecore
      if (!$('.center-info', $(this)).text().trim().length) {
        $('.center-info-outer', $(this)).hide();
      }
    });
  });
})(jQuery);