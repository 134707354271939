window.DGS.OnLoad.getInstance().register(function () {
  var jobListSpots = document.querySelectorAll('.component.job-list-spot');
  jobListSpots.forEach(function (listSpot) {
    var jobs = listSpot.querySelectorAll('li');
    jobs.forEach(function (job) {
      var wrapperLink = document.createElement('a');
      var jobLink = job.querySelector('.link');
      wrapperLink.href = jobLink.href;
      var title = job.querySelector('h3');
      var content = job.querySelector('div');
      title.parentNode.insertBefore(wrapperLink, title);
      content.parentNode.insertBefore(wrapperLink, content);
      wrapperLink.appendChild(title);
      wrapperLink.appendChild(content);
      job.append(wrapperLink);
      jobLink.remove();
    });
  });
});