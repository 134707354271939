(function ($) {
  window.DGS.ColorSpotConfig = {
    backgroundColorHeight: '100%',
    backgroundColorOpacity: 1,
    colorBorders: {
      'rgb(255, 255, 255)': '1px solid black'
    }
  };
  window.DGS.OnLoad.getInstance().register(function () {
    function guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      }
      return "".concat(s4() + s4(), "-").concat(s4(), "-").concat(s4(), "-").concat(s4(), "-").concat(s4()).concat(s4()).concat(s4());
    }
    function changeCurrent($this) {
      var $colorsContainer = $this.closest('.colors-container'),
        $thisImageParent = $('ul.images li', $colorsContainer),
        $thisParent = $this.closest('ul').find('li'),
        thisTarget = $this.attr('href').replace('#', '.'),
        $thisColorParent = $('ul.color-picker-name'),
        $targets = thisTarget != '.' ? $(thisTarget, $colorsContainer) : undefined;
      if ($('ul.color-picker-name').css('display') != 'none') {
        $thisColorParent.find('li').removeClass('current');
      }
      $thisImageParent.removeClass('current');
      $thisParent.removeClass('current');
      if ($targets != undefined) {
        $targets.addClass('current');
      }
    }
    var $spots = $('#wrapper #content .component.color-spot');
    $spots.each(function () {
      var $spot = $(this),
        $colorsContainer = $('.colors-container', $spot),
        $colorPickerLinks = $('ul.color-picker li a', $colorsContainer);
      $colorsContainer.each(function () {
        var $colorContainer = $(this),
          $colorPickerLinks = $('ul.color-picker li a', $colorContainer),
          $first = $($colorPickerLinks[0]),
          uid = guid(),
          height = window.DGS.ColorSpotConfig.backgroundColorHeight,
          opacity = window.DGS.ColorSpotConfig.backgroundColorOpacity,
          backgroundStyleCSS = "#wrapper > #content .component.color-spot .colors-container[data-uid=\"".concat(uid, "\"] .color-picker li:before { height: ").concat(height, "; }");
        $colorContainer.attr('data-uid', uid);
        $colorPickerLinks.each(function () {
          var $colorPickerLink = $(this),
            backgroundStyleTmpColorName = $colorPickerLink.attr('href').replace('#', ''),
            backgroundStyleTmpColor = $colorPickerLink.css('backgroundColor');
          backgroundStyleCSS += "#wrapper > #content .component.color-spot  .colors-container[data-uid=\"".concat(uid, "\"] .color-picker li.").concat(backgroundStyleTmpColorName, ":before { background-color: ").concat(backgroundStyleTmpColor, "; }");
          backgroundStyleCSS += "#wrapper > #content .component.color-spot  .colors-container[data-uid=\"".concat(uid, "\"] .color-picker li.current.").concat(backgroundStyleTmpColorName, ":before { opacity: ").concat(window.DGS.ColorSpotConfig.backgroundColorOpacity, ";}");
        });
        $('body').append("<style id=\"color-spot-background-".concat(uid, "\">").concat(backgroundStyleCSS, "</style>"));
        if ($first.length) {
          changeCurrent($first);
        }
      });
      $colorPickerLinks.each(function () {
        var $this = $(this),
          color = $this.css('background-color');
        $this.on('click', function (e) {
          e.preventDefault();
          changeCurrent($(this));
        });
        if (color in window.DGS.ColorSpotConfig.colorBorders) {
          $this.css('border', window.DGS.ColorSpotConfig.colorBorders[color]);
        }
      });
    });
  });
})(jQuery);