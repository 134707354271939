(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var configHandler = function configHandler(data, $spot) {
      var letterInnerNavigation = {
          className: 'letter-row',
          components: []
        },
        articleContainer = {
          className: 'article-container',
          containers: []
        },
        articleRow,
        letter,
        $letter;
      if (data.children) {
        data.children.forEach(function (letterObj) {
          if (letterObj.letter) {
            letter = letterObj.letter;
            $letter = $("<div class=\"letter\" data-article-row=\".article-row-".concat(letter, "\">").concat(letter, "</div>"));
            $letter.click(function () {
              var articleRow = $(this).data('article-row');
              $('.article-row').hide();
              $(articleRow).show();
            });
            letterInnerNavigation.components.push($letter);
            if (letterObj.children) {
              articleRow = {
                className: "article-row article-row-".concat(letter),
                components: []
              };
              letterObj.children.forEach(function (articleObj) {
                articleRow.components.push($("<div class=\"article\"><a href=\"".concat(articleObj.link, "\">").concat(articleObj.itemInfo.name, "</a></div>")));
              });
              articleContainer.containers.push(articleRow);
            }
          }
        });
      }
      return {
        mainContainer: $('.component-content', $spot),
        sections: {
          desktop: {
            containers: [{
              className: 'letter-top-navigation',
              containers: [letterInnerNavigation]
            }, articleContainer]
          }
        }
      };
    };
    window.DGS.Components.DataHierarchySpot.getInstance().register('lexicon', configHandler);
  });
})(jQuery);