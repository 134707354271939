(function ($) {
  window.DGS.CenterSpotConfig.mapBrowserWidth = true;
  window.DGS.OnLoad.getInstance().register(function () {
    var $staffLightboxContainer = $('<div class="staff-lightbox-container" />');
    $('#wrapper').append($staffLightboxContainer);
    var $staffLightbox = $('.staff-lightbox .image-spot-element');
    $staffLightbox.each(function (i, employee) {
      var $employee = $(employee);

      // Limit number of chars in employee description on desktop. Full description shown in lightbox.
      var $description = $employee.find('#Description');
      var shortLength = 110;
      var descriptionFull = $description.text();
      // Takes the content in HTML format
      var descriptionFullHtml = $description.html();
      var descriptionShortRaw = descriptionFull.substring(0, shortLength);
      // Remove everything non alpha from end of short description to make it look pretty
      var cutOff = descriptionShortRaw.match(/[^a-zA-Z]*$/).index;
      var descriptionShort = descriptionShortRaw.substring(0, cutOff);
      if (descriptionFull.length > shortLength) {
        descriptionShort += '…';
      }
      $description.html("\n        <p class=\"staff-description-long\">".concat(descriptionFull, "</p>\n        <p class=\"staff-description-short\">").concat(descriptionShort, "</p>\n      "));
      $employee.click(function (e) {
        // Only allow lightbox to open on big screens. Lightbox is forced to shut down in CSS if resized to mobile.
        if (window.matchMedia('(min-width: 601px)').matches) {
          // Fetch employee data to insert in lightbox
          var $img = $employee.find('.image-spot-element-image img').last().clone().addClass('staff-lightbox-image');
          var name = $employee.find('#name h4').last().text();
          var title = $employee.find('.image-spot-element-text .job-title').last().text();

          // Create empty elements to be inserted in lightbox
          var $staffLightboxHeaderName = $('<h4 id="staff-lightbox-header-name" />');
          var $staffLightboxHeaderTitle = $('<p class="staff-lightbox-header-title" />');
          var $staffLightboxClose = $('<div class="staff-lightbox-close" />');
          var $rightContent = $('<div class="staff-lightbox-right-content" />');
          var $staffLightboxContent = $('<div class="staff-lightbox-content" />');

          // Add employee data to lightbox elements
          $staffLightboxHeaderName.text(name);
          $staffLightboxHeaderTitle.text(title);
          $rightContent.html(descriptionFullHtml);

          // Insert elements in lightbox container
          $rightContent.prepend($staffLightboxHeaderTitle);
          $rightContent.prepend($staffLightboxHeaderName);
          $staffLightboxContent.append($staffLightboxClose);
          $staffLightboxContent.append($img);
          $staffLightboxContent.append($rightContent);
          $staffLightboxContainer.append($staffLightboxContent);

          // Close event must be bound each time lightbox is opened
          $staffLightboxClose.click(function (e) {
            $staffLightboxContainer.hide();
            $staffLightboxContainer.empty();
          });
          $staffLightboxContainer.show();
        }
      });
    });
  });
})(jQuery);