import { WDHData8 } from '../../../../wdh-feature/wdh-data8';
import { data8 } from '../../../../wdh-feature/wdh-data8/src/js/utils/data8-address_min';
var pageLang = document.documentElement.lang;
if (pageLang === 'en-gb') {
  (function (DGS, $) {
    DGS.OnLoad.getInstance().register(function () {
      var wdhdata8 = new WDHData8({
        emailLevel: 'Address',
        defaultCountry: 'GB'
      });
      wdhdata8.init([WDHData8.EMAIL]);
      function WFFMHandleResult(isValid, target) {
        if (!isValid) {
          target.parentNode.querySelector('.scfValidator').style.display = 'block';
        } else {
          target.parentNode.querySelector('.scfValidator').style.display = 'none';
        }
      }
      wdhdata8.addFieldListener('.booking-form-field [name="email"]', null, WDHData8.EMAIL, function (isValid, target) {
        if (!isValid) {
          $('#form-email-error').show();
        } else {
          $('#form-email-error').hide();
        }
      });
      wdhdata8.addFieldListener('.booking-form-field [name="phonenumber"]', null, WDHData8.PHONE, function (isValid, target) {
        if (!isValid) {
          $('#form-phonenumber-error').show();
        } else {
          $('#form-phonenumber-error').hide();
        }
      });
      wdhdata8.addFieldListener('.scfEmailTextBox', null, WDHData8.EMAIL, WFFMHandleResult);
      wdhdata8.addFieldListener('.scfTelephoneTextBox', null, WDHData8.PHONE, WFFMHandleResult);
      wdhdata8.addFieldListener('.scfSmsTelephoneTextBox', null, WDHData8.PHONE, WFFMHandleResult);
      wdhdata8.blockFormSubmit('#form');
      wdhdata8.blockEventSubmit('#submit-booking-form', 'click');

      // Note fields named with classes that include dots so use ~
      if ($('[class~="name.Post+code"] input').length) {
        var addressFieldName = $('[class~="name.Address"] input').attr('name');
        var postcodeFieldName = $('[class~="name.Post+code"] input').attr('name');
        var townFieldName = $('[class~="name.City"] input').attr('name');
      }

      // data8 ajax postcode -> address look up code from their website
      new window.data8.postcodeLookupButton([{
        element: addressFieldName,
        field: 'line1'
      }, {
        element: postcodeFieldName,
        field: 'postcode'
      }, {
        element: townFieldName,
        field: 'town'
      }], {
        // Change this to your own Ajax key
        ajaxKey: 'VToAYe6z7WD_OXgZCZWOSOxYi1KPWhyhr6q1qiPcFAY',
        findLabel: 'Find address',
        // Change this to your postcode lookup license type
        license: 'WebClickFull'
      }).show();

      // Hide address and city fields until postcode -> address look up button is pushed
      $('.component.form').each(function () {
        var $this = $(this);
        $('[class~="name.Address"]', $this).parent().parent().css('display', 'none'); // 31-1-19
        $('[class~="name.City"] input', $this).css('display', 'none');
        $('[class~="name.Post+code"] div', $this).css('display', 'flex'); // 30-1-19
        $('[class~="name.Post+code"] div', $this).addClass('data8-container'); // 30-1-19

        $('input.postcodeLookup', $this).on('click', function () {
          $('[class~="name.Address"]', $this).parent().parent().css('display', 'block'); // 31-1-19
          $('[class~="name.City"] input', $this).css('display', 'block');
        });
      });
    });
  })(window.DGS, jQuery);
}