var componentsSchemas = {
  'intro-banner': {
    data: [{
      name: 'image',
      element: '.intro-banner-image img',
      type: 'image'
    }, {
      name: 'mobileImage',
      element: '.intro-banner-image',
      type: 'mobileImage'
    }, {
      name: 'video',
      element: '.intro-banner-video source',
      type: 'video'
    }, {
      name: 'embeddedVideo',
      element: '.intro-banner-video-external iframe',
      type: 'iFrame'
    }, {
      name: 'content',
      element: '.intro-banner-text',
      type: 'html'
    }, {
      name: 'link',
      element: '.intro-banner-link',
      type: 'html'
    }, {
      name: 'anchorLink',
      element: '.sticky-downlink a',
      type: 'link'
    }, {
      name: 'textList',
      element: '.intro-banner-list-spot-content',
      type: 'list'
    }]
  },
  'rich-text': {
    data: [{
      name: 'content',
      element: '.component-content',
      type: 'html'
    }]
  },
  'text-image-spot': {
    data: [{
      name: 'image',
      element: '.text-image-spot-image',
      type: 'image'
    }, {
      name: 'imageDescription',
      element: '.text-image-spot-image-description',
      type: 'text'
    }, {
      name: 'content',
      element: '.text-image-spot-text',
      type: 'html'
    }, {
      name: 'link',
      element: '.text-image-spot-image a',
      type: 'link'
    }]
  },
  'screening-test-spot': {
    data: [{
      name: 'title',
      element: '.questions-intro .dynamic-header h2',
      type: 'text'
    }, {
      name: 'description',
      element: '.questions-intro .intro-description',
      type: 'text'
    }, {
      name: 'submitButton',
      element: '.questions-intro .start-test',
      type: 'text'
    }, {
      name: 'questionsAndResults',
      element: '.questions',
      type: 'screeningTestList'
    }, {
      name: 'footer',
      element: '.questions-intro-footer',
      type: 'text'
    }]
  },
  'image-spot': {
    data: [{
      name: 'elements',
      element: '.image-spot-elements',
      type: 'imageSpotList'
    }]
  },
  'two-section-grid': {
    data: [{
      name: 'sectionOne',
      element: '.section-one',
      type: 'html'
    }, {
      name: 'sectionTwo',
      element: '.section-two',
      type: 'html'
    }]
  },
  'three-section-grid': {
    data: [{
      name: 'sectionOne',
      element: '.section-one',
      type: 'html'
    }, {
      name: 'sectionTwo',
      element: '.section-two',
      type: 'html'
    }, {
      name: 'sectionThree',
      element: '.section-three',
      type: 'html'
    }]
  },
  container: {
    data: [{
      name: 'content',
      element: '.component-content',
      type: 'html'
    }]
  },
  header: {
    data: [{
      name: 'bigLogo',
      element: '.section-one .header-logo a img',
      type: 'image'
    }, {
      name: 'smallLogo',
      element: '.section-two .header-logo a img',
      type: 'image'
    }, {
      name: 'bigLogoLink',
      element: '.section-one .header-logo a',
      type: 'link'
    }, {
      name: 'smallLogoLink',
      element: '.section-two .header-logo a',
      type: 'link'
    }, {
      name: 'languageSelector',
      element: '.language-selector-spot .component-content',
      type: 'languagesList'
    }, {
      name: 'search',
      element: '.search-field-spot ',
      type: 'search'
    }, {
      name: 'header',
      element: '.component-content',
      type: 'headerContent'
    }, {
      name: 'headerCta',
      element: '.component-content',
      type: 'headerCta'
    }]
  },
  'hearing-test-invitation': {
    data: [{
      name: 'title',
      element: '.hti-title',
      type: 'text'
    }, {
      name: 'description',
      element: '.hti-description',
      type: 'html'
    }, {
      name: 'confirmationUrl',
      element: '.hti-confirmation-url',
      type: 'text'
    }, {
      name: 'hearingTestUrl',
      element: '.hti-hearing-test-url',
      type: 'text'
    }, {
      name: 'recipientNameLabel',
      element: '.hti-recipient-name-label',
      type: 'text'
    }, {
      name: 'recipientEmailLabel',
      element: '.hti-recipient-email-label',
      type: 'text'
    }, {
      name: 'senderNameLabel',
      element: '.hti-sender-name-label',
      type: 'text'
    }, {
      name: 'senderEmailLabel',
      element: '.hti-sender-email-label',
      type: 'text'
    }, {
      name: 'messageLabel',
      element: '.hti-message-label',
      type: 'text'
    }, {
      name: 'messageDefaultTextLabel',
      element: '.hti-message-default-text-label',
      type: 'text'
    }, {
      name: 'submitButtonLabel',
      element: '.hti-submit-button',
      type: 'text'
    }, {
      name: 'confirmationTitle',
      element: '.hti-confirmation-title',
      type: 'text'
    }, {
      name: 'confirmationDescription',
      element: '.hti-confirmation-description',
      type: 'html'
    }, {
      name: 'submitTitle',
      element: '.hti-submit-title',
      type: 'text'
    }, {
      name: 'submitDescription',
      element: '.hti-submit-description',
      type: 'html'
    }, {
      name: 'errorTitle',
      element: '.hti-submit-error-title',
      type: 'text'
    }, {
      name: 'errorExpired',
      element: '.hti-submit-error-expired',
      type: 'text'
    }, {
      name: 'errorNotExists',
      element: '.hti-submit-error-not-exists',
      type: 'text'
    }, {
      name: 'utmTracking',
      element: '.hti-utm-tracking',
      type: 'text'
    }, {
      name: 'gaCookieId',
      element: '.hti-ga-cookie-id',
      type: 'text'
    }, {
      name: 'trackingCodeOne',
      element: '.hti-tracking-code-one',
      type: 'text'
    }, {
      name: 'trackingCodeTwo',
      element: '.hti-tracking-code-two',
      type: 'text'
    }, {
      name: 'trackingCodeOneName',
      element: '.hti-tracking-code-one-name',
      type: 'text'
    }, {
      name: 'trackingCodeTwoName',
      element: '.hti-tracking-code-two-name',
      type: 'text'
    }, {
      name: 'inviteAnother',
      element: '.hti-invite-another-label',
      type: 'text'
    }]
  },
  'diary-management-online-booking': {
    data: [{
      name: 'apiUrl',
      element: '#form-ApiURL',
      type: 'text'
    }, {
      name: 'apiKey',
      element: '#form-Apikey',
      type: 'text'
    }, {
      name: 'apiLegalEntity',
      element: '#form-Apilegal',
      type: 'text'
    }, {
      name: 'apiSubscriptionKey',
      element: '#form-Apisubkey',
      type: 'text'
    }, {
      name: 'webserviceId',
      element: '#form-Apiflow',
      type: 'text'
    }, {
      name: 'calendarDays',
      element: '#form-CalendarDays',
      type: 'text'
    }, {
      name: 'privacyPolicyUrl',
      element: '#privacy-policy-url',
      type: 'text'
    }, {
      name: 'utmTracking',
      element: '.utm-tracking',
      type: 'text'
    }, {
      name: 'gaCookieId',
      element: '.ga-cookie-id',
      type: 'text'
    }, {
      name: 'trackingCodeOne',
      element: '.tracking-code-one',
      type: 'text'
    }, {
      name: 'trackingCodeTwo',
      element: '.tracking-code-two',
      type: 'text'
    }, {
      name: 'trackingCodeOneLabel',
      element: '.tracking-code-one-name',
      type: 'text'
    }, {
      name: 'trackingCodeTwoLabel',
      element: '.tracking-code-two-name',
      type: 'text'
    }, {
      name: 'utmSource',
      element: '.Utm_source',
      type: 'text'
    }, {
      name: 'utmCampaign',
      element: '.Utm_campaign',
      type: 'text'
    }, {
      name: 'utmContent',
      element: '.Utm_content',
      type: 'text'
    }, {
      name: 'utmTerm',
      element: '.Utm_term',
      type: 'text'
    }, {
      name: 'googleMapsApi',
      element: '#google-maps-api',
      type: 'text'
    }, {
      name: 'mapPosition',
      element: '#map-position',
      type: 'text'
    }, {
      name: 'locales',
      element: '#locales-settings',
      type: 'json'
    }]
  },
  'video-player': {
    data: [{
      name: 'iframe',
      element: '.video-player-iframe',
      type: 'html'
    }, {
      name: 'content',
      element: '.video-player-content',
      type: 'html'
    }, {
      name: 'thumbnail',
      element: '.video-player-thumbnail-image img',
      type: 'image'
    }, {
      name: 'isOverlay',
      element: '.video-player-use-thumbnail',
      type: 'textToBool'
    }, {
      name: 'useThumbnail',
      element: '.video-player-in-overlay-window',
      type: 'textToBool'
    }]
  },
  'center-locator': {
    data: []
  },
  'flex-spot-advanced': {
    data: [{
      name: 'preElementItems',
      element: '.pre-element',
      subElement: '.pre-element__item',
      type: 'componentItemList'
    }, {
      name: 'preElementTaggedItems',
      element: '.pre-element',
      subItem: '.pre-element__item',
      type: 'componentItemListTagged'
    }, {
      name: 'preElementTags',
      element: '.pre-element',
      type: 'tagListForElementOrItem'
    }, {
      name: 'postElementItems',
      element: '.post-element',
      subElement: '.post-element__item',
      type: 'componentItemList'
    }, {
      name: 'postElementTaggedItems',
      element: '.post-element',
      subItem: '.post-element__item',
      type: 'componentItemListTagged'
    }, {
      name: 'postElementTags',
      element: '.post-element',
      type: 'tagListForElementOrItem'
    }, {
      name: 'elements',
      element: '.component-content',
      subElement: '.element',
      type: 'elementItems'
    }, {
      name: 'taggedElements',
      element: '.component-content',
      subElement: '.element',
      type: 'taggedElementItems'
    }]
  }
};
export default componentsSchemas;