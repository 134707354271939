(function ($, DGS, moment) {
  DGS.OnLoad.getInstance().register(function () {
    $('.blog--two-col .component.related-blogs').each(function () {
      var $rteBefore = $(this).prev('.component.rich-text'),
        $thisColumn = $(this).parent("[class*='section']");
      $(this).addClass('component-ready animate');
      $rteBefore.addClass('component-ready animate');
      $rteBefore.addClass('related-blogs-header');
      $thisColumn.addClass('section-with-header');
      moment.locale($("html").attr("lang"));
      $('li', $(this)).each(function () {
        // Now change the date format
        var $newsDate = $('.news-date', $(this));
        var dictItem = $newsDate.data("blog-date"); //{0:dd/MM/yy}
        var result = dictItem.split(":").length > 1 ? dictItem.split(":")[1] : "DD/MM/YY";
        var dateFormat = result.split("}").length > 1 ? result.split("}")[0].toUpperCase() : "DD/MM/YY";
        if ($newsDate.length > 0 && $newsDate.html() !== '') {
          var momentDate = moment($newsDate.html(), dateFormat);
          $newsDate.html(momentDate.format('DD MMMM YYYY'));
        }
        $newsDate.insertAfter($('.news-title', $(this)));
      });
    });
  });
})(jQuery, window.DGS, window.moment);