import { onLoad } from '@demant/wdh';
import jQuery from 'jquery';
var $ = jQuery;
var hideChat = function hideChat() {
  $('.inin-chat').removeClass('inin-chat-active');
};
var saveChatClass = function saveChatClass() {
  localStorage.clear();
  var y = $('.inin-chat').attr('class');
  localStorage.setItem('ActiveName', y);
};
onLoad(function () {
  var mq = window.matchMedia('(min-width: 768px)');
  if (mq.matches) {
    $('.inin-chat').addClass('inin-chat-active');
  }
  $('.inin-chat-body p').html('<p style="margin-bottom:10px;">Welcome to Audika, we are here to help.</p> <p>Enter your name and click start chat…</p>');
  $('.inin-chat-heading').on('click', function (e) {
    e.stopPropagation();
    console.log('click heading');
    $('.inin-chat').toggleClass('inin-chat-active');
    saveChatClass();
  });
  $('.inin-chat-close').on('click', function (e) {
    console.log('click close');
    e.stopPropagation();
    hideChat();
    saveChatClass();
  });
  $('#inin-chat-form').on('submit', function (e) {
    e.preventDefault();
    var chatUsername = $('[name="chatUsername"]').val();
    if ($.trim(chatUsername) === '') {
      alert('Please insert your name to continue');
      return;
    }
    window.open("".concat($(this).attr('action'), "?chatUsername=").concat(chatUsername), 'ININ_Chat', 'location=yes,resizable=yes,scrollbars=yes,width=500,height=550');
  });
  var ininStorageClassName = localStorage.getItem('ActiveName');
  console.log('ininStorageClassName', ininStorageClassName);
  if (ininStorageClassName && ininStorageClassName !== 'undefined' && ininStorageClassName !== 'inin-chat-active') {
    $('.inin-chat').attr('class', ininStorageClassName);
  }
});