(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.component.redirect-notification-spot').each(function () {
      var spot = $(this);
      if ($('.component-content *', spot).length > 0) {
        spot.css('display', 'block');
        $('.component-content', spot).after().click(function () {
          spot.hide();
        });
      }
    });
  });
})(jQuery);