export var FW_GA_EVENT_NAME = 'GAevent';
export var FW_GA_VIRTUAL_PAGE_VIEW = 'virtualpageview';
export var FW_GA_CATEGORY_HEARING_TEST = 'Do you need a hearing test?';
export var FW_GA_CATEGORY_AUDI = 'Do you want to try our new hearing aid for free?';
export var FW_QUESTION_NO = function FW_QUESTION_NO(questionNo) {
  return "Question ".concat(questionNo, " answered");
};
export var FW_STEP_NO = function FW_STEP_NO(stepNo) {
  return "Step ".concat(stepNo, " answered");
};
export var FW_RESULT = 'Result';
export var FW_SEND_CONTACT = 'Send contact information';