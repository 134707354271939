(function ($) {
  DGS.Resizer.getInstance().register(function () {
    // hide all forms in css and load then with JS when all info is ready

    // decide if form will fit inside intro banner or should it stack
    $('.component.intro-banner + .component.form.form-on-top').each(function () {
      $(this).removeClass('form-overflow');
      var bannerHight = $(this).prev('.component.intro-banner').outerHeight();
      var availableSpace = bannerHight - $('#header').outerHeight();
      var form = $(this).find('.scfForm');
      if (availableSpace <= form.outerHeight()) {
        $(this).addClass('form-overflow');
      } else {
        var topMargin = 0 - form.outerHeight() - 20;
        form.css({
          top: topMargin
        });
      }
    });

    // for wide form place the button inside .scfSectionContent container
    $('.component.form.align-button').each(function () {
      $button = $(this).find('.scfSubmitButtonBorder');
      $content = $(this).find('.scfSectionContent');
      $content.append($button);
    });
    $('.component.form.telNumLengthValidate .scfTelephoneTextBox').attr('minlength', '10').attr('maxlength', '10');
    $('.component.form.telNumLengthValidate .scfSubmitButton').click(function (e) {
      var $telephone = $(this).closest('.component.form.telNumLengthValidate').find('.scfTelephoneTextBox');
      $telephone.each(function () {
        if ($(this).val().length != 10 || $(this).val().match(/^\d+$/) == null) {
          e.preventDefault();
          $(this).nextAll('.scfValidator').first().show();
        } else {
          $(this).nextAll('.scfValidator').first().hide();
        }
      });
    });
    setTimeout(function () {
      $('.component.form').each(function () {
        $(this).css('opacity', 1);
      });
    }, 1000);
  });
  DGS.OnLoad.getInstance().register(function () {
    // set placeholders from labels
    $('.component.form .scfSingleLineTextBox, .component.form .scfEmailTextBox, .component.form .scfTelephoneTextBox, .component.form .scfMultipleLineTextBox').each(function () {
      var $label = $(this).parent().prev('label').text();
      $(this).attr('placeholder', $label);
    });
    var wffm = $('.component.form').wffm({
      usePhoneTypeInput: true
    });
  });
  DGS.OnContentLoaded.getInstance().register(function () {
    // Validation is realized on server side. All errors are placed in div with scfValidationSummary class.
    var formContainer = document.getElementsByClassName('scfForm'); // Get form container.
    var formValidationContainer = document.getElementsByClassName('scfValidationSummary'); // Get form validation container.
    var formValidationContent = $(formValidationContainer).text().trim(); // Get validation container content.
    if (formValidationContent.length !== 0 && formContainer.length !== 0) {
      // If it is some validation errors...
      var formName = formContainer[0].querySelector('h1, h2, h3'); // Get form header name. It can be h1, h2 or h3, so select first one occurred.
      formName = formName !== null ? formName.innerHTML.trim() : 'undefined form';
      window.DGS.Event.getInstance().trigger('trackFValidationErrors', formName, formValidationContent); // ... register them in GTM
    }
    var wffmSubmitButtons = document.querySelectorAll('.scfForm input[type=submit]');
    wffmSubmitButtons.forEach(function (submit) {
      submit.addEventListener('click', function () {
        var loader = document.createElement('span');
        loader.classList.add('form-spinner');
        submit.parentNode.appendChild(loader);
        submit.style.display = 'none';
        setTimeout(function () {
          loader.remove();
          submit.style.display = 'block';
        }, 2000);
      });
    });
  });
})(jQuery);